@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.tag {
  display: inline-flex;
  text-align: center;
  white-space: nowrap;
  position: relative;
  padding: 3px 7px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 50px;
  background-color: $light-gray-background;
  color: var(--text-color, $secondary-color);
  border: none;
  font-size: $font-size-12;

  &.blackoutMode {
    background-color: $gray9;
    color: $white-color;
  }

  &.pinkTag {
    background-color: $tertiary-color;
    color: $white-color;
    font-weight: 600;
    border: none;
  }

  &.whiteTag {
    background-color: $white-color;
    color: $black-color;
    border: none;
  }

  &.yellowTag {
    background-color: $yellow-color;
    color: #215297;
    font-weight: bold;
    border: none;
  }

  &.blackout {
    background-color: #000000;
    color: $white-color;
    font-weight: 600;
    max-height: 20px;
  }
  &.seguroGratis {
    background-color: #7896ff;
    color: #23345a;
    font-weight: 600;
    max-height: 20px;
  }
  &.clubResale {
    background-color: $white-color;
    color: $blue1;
    border: none;
    font-size: 14px;
    font-weight: 600;
  }
}

.primaryTag {
  text-align: center;
  white-space: nowrap;
  padding: 0px 7px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 50px;
  font-size: $font-size-12;
  border: 1px solid white;
  background-color: $primary-color;
  color: white;
  display: flex;
  align-items: center;
}

.deletable {
  @extend .tag;
  margin-top: 6px;
  background-color: $lavender0;
  border: none;
  color: var(--text-color, $secondary-color);
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 2px;

  .closeButton {
    all: unset;
    display: block;
    margin-left: -5px;
    border: none;
    position: relative;
    cursor: pointer;
    background: none;

    &:active {
      outline: none;
    }

    svg {
      margin-bottom: -28%;

      @include screen(xs) {
        margin-bottom: -25%;
      }
    }
  }

  @include screen(xs) {
    padding: 1.5%;
    padding-left: 2%;
  }

  @include screen(sm) {
    padding: 1%;
    padding-left: 1.5%;
  }
}

b {
  padding-left: 2.5px;
}

.icon {
  height: 16px;
  width: 16px;
  display: inline-block;
  margin-right: 5px;
  align-self: center;

  &.blackoutIcon {
    width: 52px;
    height: 52px;
    margin-right: 0;
  }

  &.blackoutDayIcon {
    width: 42px;
    height: 18px;
    margin-right: 0;

    img {
      object-fit: cover !important;
    }
  }

  [class*='image'] {
    image-rendering: -webkit-optimize-contrast;
  }
}

.leafIcon {
  height: 16px;
  display: inline-block;
  margin-right: 5px;
}

.campaignTag {
  font-weight: 600;
  border: unset;
  max-height: 20px;
}

.megaFeiraoEmgea {
  path {
    fill: #fa9003;
  }
}

.feirao15 {
  path {
    fill: #006854;
  }
}

.melhorProposta {
  path {
    fill: #fa8f00;
  }
}
