@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

@include screen(xs) {
  .modalClass {
    max-height: 90vh;

    button {
      margin: 0px;
    }
  }

  @supports (-webkit-touch-callout: none) {
    .modalClass {
      > span {
        transform: translateY(25px);
      }
    }
  }
}

.alertModal {
  display: flex;
  flex-direction: column;
  margin: 60px 14px 9px 14px;
  padding: 15px 0;

  @include screen(xs) {
    @supports (-webkit-touch-callout: none) {
      margin-top: 90px;
    }
  }

  .horizontalLine {
    border: none;
    width: 100%;
    border-top: 1px solid $gray2;
    margin-top: 5px;
  }

  .main {
    @include flexBox(column, space-between);

    .filtersContainer {
      max-height: 0px;
      overflow: hidden;
      transition: all 0.5s linear;
      padding-top: 10px;

      @include screen(max-sm) {
        display: none;
      }

      &.expand {
        max-height: 5000px;
      }

      .filterWrapper {
        column-count: 2;
        break-before: column;
        column-fill: balance;
        column-gap: 60px;
        column-width: 140px;

        > section {
          margin: 10px;
          page-break-inside: avoid;
          display: block;
          width: 90%;
        }

        @include screen(max-sm) {
          column-count: 1;
        }
      }
    }

    .purposeSection {
      margin-top: 30px;
      margin-bottom: 15px;
      width: 100%;

      > p {
        text-align: center;
      }
    }

    .contactSection,
    .periodicitySection {
      justify-content: flex-start;
      width: 90%;

      @include screen(min-md) {
        justify-content: center;
        width: 70%;

        > p {
          text-align: center;
        }
      }
    }

    .periodicitySection {
      & > * {
        margin-top: 12px;
      }

      .available {
        width: 142px;
      }

      .daily {
        width: 196px;
      }

      .weekly {
        width: 226px;
      }
    }

    .contactSection {
      .alertInput:not(.inputPhone),
      .whatsapp {
        margin: 10px 0;
      }

      .whatsapp {
        display: flex;
        margin-bottom: 0;
      }

      .inputPhone {
        max-height: 0px;
        overflow: hidden;
        transition: all 0.5s linear;
      }

      .inputPhone.expand {
        max-height: 90px;
        padding: 1px;
      }
    }

    .optinsSection {
      margin: 12px 0 12px 0;
    }
  }

  > .footer {
    display: inline-flex;
    align-items: center;
    flex-direction: column;

    > hr {
      border-top: 1px $gray2 solid;
      width: 100%;
      margin-bottom: 10px;
    }

    > section {
      margin: 12px 0 16px 0;
    }
  }

  .submit {
    width: 327px;
    margin-bottom: 10px;
    margin-top: 20px;

    @include screen(xs) {
      width: 100%;
    }
  }

  .purposeOption {
    @include screen(max-sm) {
      max-width: 38vw;
      font-size: calc(8px + 0.25vw);

      @media (max-width: 320px) {
        font-size: calc(6px + 0.25vw);
      }
    }
  }

  @media (max-width: 481px) {
    margin-bottom: 20%;

    .periodicitySection {
      flex-direction: column;
    }
  }
}

.purposes {
  padding-left: 17%;
}
