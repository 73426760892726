@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.container {
  display: flex;
  align-items: center;
  margin-top: 36px;
  margin-bottom: 52px;

  @include screen(max-md) {
    display: flex;
    flex-direction: column;
  }

  > p {
    width: 400px;
    font-style: normal;
    font-weight: 400;
    font-size: $font-size-28;
    line-height: 40px;
    letter-spacing: 0.462857px;
    color: $blue1;
    margin-right: 30px;

    @include screen(max-md) {
      order: 2;
      margin-top: 35px;
      margin-right: 0;
    }
  }

  b {
    font-weight: 900;
  }

  @include screen(xs) {
    > svg {
      max-width: 327px;
    }

    > p {
      max-width: 327px;
    }
  }
}
