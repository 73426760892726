@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';

main {
  .noBorder {
    max-height: 613px;
    border-top: none;
    padding-top: 0px;
    padding-bottom: 12px;
  }

  @include screen(max-md) {
    .noBorder {
      margin: 0;
    }
  }
}
