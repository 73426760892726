@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.feature-row {
  display: flex;
  align-items: center;
  margin-right: 20px;

  .blackout {
    color: $white-color !important;
  }

  .icon {
    margin-right: 5px;
    width: 18px;
  }

  .propertyCard {
    @include screen(max-md) {
      svg {
        display: none;
      }

      border-radius: 50%;
      display: inline-block;
      height: 5px;
      width: 5px;
      background-color: var(--primary-color, #7896ff);
    }
  }

  .row-text {
    display: inline-flex;
    justify-content: center;
    flex-direction: row;
  }

  .label {
    margin-left: 3px;
    color: var(--text-color, #262626);
  }
}

.feature-rowWithoutName {
  @extend .feature-row;

  margin-right: 15px;
}

.feature-column {
  text-align: center;
}

.icon {
  margin: 0 auto;
  width: 35px;
  height: 32px;
}

.column-text {
  min-width: 0;

  .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;
    color: var(--text-color, #262626);
  }
}
