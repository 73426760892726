@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.container {
  position: relative;

  button[class*='react-multiple-carousel__arrow'] {
    box-shadow: 0px 15px 20px rgba(197, 197, 197, 0.4);
    color: #fff9f9 !important;
    margin: 0 auto;
    transition: all 0.3s ease 0s;
    top: 50%;
    background-color: #f9f9f9 !important;
    border-radius: 50%;
    z-index: 1;
    min-width: unset;
    min-height: unset;
    width: 32px;
    height: 32px;

    &::before {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $black-color;
      font-size: 12px;
      text-align: center;
    }
    &:hover {
      color: #111111 !important;
    }
  }

  div[class*='magic-dots'] {
    position: absolute !important;
    bottom: 4% !important;
    margin: 0 auto;

    > ul {
      > li {
        button {
          &::before {
            opacity: unset;
            color: $white-color;
          }
        }
      }

      > li[class*='active'] {
        button {
          &::before {
            color: $primary-color;
          }
        }
      }
    }
  }

  div[class*='slick-slider'] {
    display: flex;
    justify-content: center;
  }

  div[class*='slick-list'] {
    width: 100%;
    min-height: 200px;
    height: 200px;
    max-height: 200px;
  }
}
