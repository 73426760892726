@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.shareList {
  padding: 11px 6px 11px 12.5px;
  max-width: 320px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  border: 1px solid;
  border-color: $lavender0;
  border-radius: 50px;
  align-self: center;

  .title {
    color: #666666;
    font-weight: 400;
    line-height: 18px;
    font-size: 14px;
  }

  .logo {
    margin: 0px 5px;
    display: flex;
    width: 30px;
    height: 30px;
  }

  @include screen(min-lg) {
    align-self: flex-end;
  }
}
