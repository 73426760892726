@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

$small-screen: 768px;

.modal {
  position: absolute;
  top: 50;
  bottom: 50;
}

.card {
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.05);
  background-color: $white-color;
  border: 1px solid $gray0;
  border-radius: 4px;
  padding: 1.5em;
  margin-top: 0px;

  .banner {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      object-fit: none;
      @media (max-width:400px) {
        max-width: 320px;
        object-fit: contain;
      }
    }

    > div  {
      width: 100%;
    }
  }
}

.link {
  text-decoration: none;
}

@include screen(max-md) {
  .singleButtonContainer {
    position: fixed;
    bottom: 0;
    width: 100%;
    align-self: center;
    background-color: $white-color;
    text-align: center;

    > button {
      width: 90%;
    }
  }

  .buttonContainer {
    position: fixed;
    bottom: 0;
    width: 100%;
    align-self: center;
    background-color: $white-color;
    display: flex;
    padding: 15px;
    z-index: 1;
    gap: 12px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .card {
    display: flex;
    flex-direction: column;
    margin: 0px 0;

    box-shadow: none;
    border: none;
    border-radius: none;
    background-color: $asterisk-background-color;
    position: relative;
    padding: 1em 1.2em;
  }
}

.linkDivMargin {
  margin-top: 8%;
}

.none {
  display: none;
}

.subTitleCampaign {
  color: #888888;
  font-size: 0.6em;
  font-weight: 500;
  margin-right: 15px;
}

.countDown {
  text-align: center;
  margin-bottom: -20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 10px 0 10px;
  @media (max-width:1024px) and (min-width:550px) {
    width: 50%;
  }
}