@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';
@import '../AprraisedValue/AprraisedValue.module.scss';

.span {
  font-size: $font-size-24;
  color: $secondary-color;
  font-weight: 700;
  white-space: nowrap;

  &.campaign {
    text-decoration: line-through;
    font-size: 16px;
    color: $secondary-color;
    font-weight: normal;
  }

  &.blackout {
    color: $white-color !important;
  }

  @include screen(xs) {
    font-size: 1.7em;
  }
}
