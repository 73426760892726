@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.container {
  box-sizing: border-box;
  width: 100%;
  height: 432px;
  background-color: $gray11;
  display: flex;
  margin-bottom: 80px;

  .imgContainer {
    width: 50%;
    min-width: 50%;
    background-image: url('../../assets/images/increaseYourPortfolio.webp');
    background-size: cover;
    background-position: center top;
  }

  .textContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 48px;

    h1 {
      width: 75%;
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: 0.462857px;
      color: $black-color;
    }

    button {
      background-color: var(--cta-primary-color, $tertiary-color);
      color: var(--cta-primary-text, white);
      width: 302px;
      height: 44px;
      border-radius: 8px;
      margin-top: 24px;
    }
  }

  @include screen(max-sm) {
    flex-direction: column;
    height: auto;

    .imgContainer {
      width: 100%;
      height: 300px;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .textContainer {
      box-sizing: border-box;
      width: 98%;
      margin-left: unset;
      justify-content: center;
      align-items: center;
      padding: 24px 24px 0 24px;

      h1 {
        width: 100%;
      }

      button {
        margin-bottom: 36px;
      }
    }
  }
}
