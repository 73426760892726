@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.servicesWeSeek {
  width: 100%;
  position: relative;
  margin-top: 72px;
  padding: 0 2px;

  @include screen(min-md) {
    padding: 0 28px;
  }

  h1 {
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 32px;
  }

  .iconContainer {
    margin: 0 8px 0 8px;
    min-height: 208px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: $gray11;
    gap: 14px;

    > svg {
      width: 44px;
      height: 44px;

      path {
        fill: var(--primary-color);
      }
    }

    > h2 {
      text-align: center;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.list {
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  max-width: 100vw;

  > button {
    margin: 0 -2.5% !important;
    background-color: rgba(255, 255, 255, 0) !important;
    color: #333 !important;
    margin: 0 auto;
    transition: all 0.3s ease 0s;
    top: 45%;

    &::before {
      color: #888;
      font-size: 36px;
      margin-right: 28px;
    }
    &:hover {
      background-color: #f4f4f40c !important;
    }
  }
}
