.modalContainer {
  height: 80vh;

  &.btnWhite {
    [class*='clear'] > button {
      margin: 12px 0 0 -4px;

      use {
        fill: #ffffff;
      }
    }
  }

  .formContainer {
    height: 100%;
  }

  .form {
    height: 100%;
  }
}
