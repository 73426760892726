@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

$input-height-increment: 8px;
.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 48%;

  .inputWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .input:focus {
    outline: 0;
  }

  .input {
    background: $white-color;
    border-radius: 6px;
    border: solid 1px $gray2;
    box-sizing: border-box;
    color: $lavender1;
    font-size: $font-size-14;
    height: calc(28px + #{$input-height-increment});
    padding: 6px 15px 4px 31px;
    position: relative;
    // width: 250px;
  }

  .label {
    color: $blue1;
    font-size: $font-size-14;
    left: 13px;
    position: absolute;
    top: calc(3px + #{$input-height-increment * 0.7});
    z-index: 1;
  }

  .itemLabel {
    display: flex;
    flex-direction: column;
    color: $gray4;
    margin-bottom: 2px;

    @include screen(max-sm) {
      height: 40px;
      justify-content: center;

      p {
        font-size: $font-size-12;
      }
    }
  }
}
