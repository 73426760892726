@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

@include screen(xs) {
  @supports (-webkit-touch-callout: none) {
    .modalClass {
      > span {
        transform: translateY(15px);
      }
    }
  }
}

.messageModal {
  display: flex;
  flex-direction: column;
  margin: 9px 14px;
  zoom: 1.025;

  // for IE and Edge
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    zoom: 1;
  }

  @include screen(xs) {
    padding-bottom: 40%;
    @supports (-webkit-touch-callout: none) {
      margin-top: 29px;
    }
  }

  > .header {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    > .info {
      display: flex;
      flex-direction: column;

      .contactText {
        margin-top: -2px;
      }
    }

    > .logo {
      margin: 0;
      position: absolute;
      right: 25px;
      top: 0;
      max-width: 120px;
      max-height: 58px;
    }

    @include screen(xs) {
      flex-direction: column-reverse;
      > .logo {
        margin: 0;
        top: 0;
        position: unset;
        width: 150px;
        align-self: center;
      }
    }
  }

  > .main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 13px;

    > .inputName {
      width: 100%;
      margin-top: 17px;
    }

    > .inputPhone {
      margin-top: 5px;
      width: 50%;
      padding-left: 3%;
      box-sizing: border-box;
    }

    > .inputEmail {
      margin-top: 5px;
      width: 50%;
      padding-right: 2%;
      box-sizing: border-box;
    }

    > .inputSubject {
      width: 100%;
      margin-top: 6px;
    }

    > .inputMessage {
      width: 100%;
      margin-top: 3px;

      textarea {
        resize: none;
        padding: 10px 11px;
        height: 120px;
      }
    }

    .modalLabel {
      margin-bottom: 3px;
    }

    input {
      height: 40px;
    }

    > .inputName,
    > .inputPhone,
    > .inputSubject,
    > .inputMessage {
      position: relative;

      > span {
        position: absolute;
        bottom: -16px;
        right: 0;
        font-size: $font-size-12;
      }
    }

    > .inputEmail {
      position: relative;

      > span {
        position: absolute;
        bottom: -16px;
        right: 11px;
        font-size: $font-size-12;
      }
    }
  }

  > .footer {
    justify-content: center;
    display: flex;
  }
}
