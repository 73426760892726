@import '../../assets/scss/variables.scss';

.toast {
  padding: 1em !important;
  border-radius: 4px !important;
  background: white !important;
  box-shadow: 2px 2px 5px black;
  color: var(--text-color, $blue1) !important;
}

.success {
  background: var(--primary-color, $primary-color) !important;
}

.error {
  background: $tertiary-color !important;
}
