@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.largeButton {
  border-radius: 8px;
  margin: 0;
  width: 50%;
  background-color: var(--cta-secondary-color, $lavender1);
  color: var(--cta-secondary-text, white);
}

.fullButton {
  width: 100% !important;
}

.halfButton {
  border-radius: 8px;
  width: 50%;
  background-color: var(--cta-secondary-color, $lavender1);
  color: var(--cta-secondary-text, white);
}

.horizontalContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  gap: 5px;
}

.spacing {
  margin-top: 20px !important;
}

@include screen(max-md) {
  .largeButton {
    width: 48%;
  }
  .halfButton {
    width: 48%;
  }
  .fullButton {
    width: 98% !important;
  }
}
