@import '../../assets/scss/variables.scss';

.arrow {
  height: 100%;
  position: absolute;
  padding: 3px;
  outline: 0;

  &:hover {
    background-color: #00000047;
  }
}

.rightButton {
  right: 0px;
}

.leftButton {
  left: 0px;
}

.rightIcon {
  transform: rotate(180deg);
}

.rightIconIOS {
  transform: rotate(270deg);
}

.leftIconIOS {
  transform: rotate(90deg);
}
