@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.howItWorks {
  width: 100%;
  margin: 54px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 44px;

  .img {
    max-width: 50%;
    img {
      object-fit: cover;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    h3,
    p {
      max-width: 480px;
      color: var(--text-color);
    }

    h3 {
      font-size: 36px;
      font-weight: 700;
      line-height: 43.2px;
      margin-bottom: 24px;
    }

    p {
      font-size: 18px;
      line-height: 28.8px;
      font-weight: 300;
    }

    @include screen(xxl) {
      p {
        font-size: 24px;
      }
    }
  }

  @include screen(max-md) {
    gap: 36px;
    .img {
      max-width: 50%;

      img {
        max-width: 44vw;
      }

      display: flex;
      justify-content: center;
    }

    .content {
      display: flex;
      flex-direction: column;
      max-width: 90%;

      h3 {
        color: var(--text-color);
        font-size: 32px;
        font-weight: 700;
        line-height: 39.2px;
      }

      p {
        max-width: 50vw;
        font-size: 20px;
        line-height: 24.8px;
      }
    }
  }

  @include screen(max-sm) {
    flex-direction: column;
    justify-content: center;
    gap: 36px;

    .img {
      max-width: 100%;

      img {
        max-width: 90%;
      }

      display: flex;
      justify-content: center;
    }

    .content {
      display: flex;
      flex-direction: column;
      max-width: 90%;
      justify-content: center;
      align-items: center;

      h3 {
        max-width: 480px;
        color: var(--text-color);
        font-size: 32px;
        font-weight: 700;
        line-height: 39.2px;
        text-align: center;
      }

      p {
        max-width: unset;
        font-size: 20px;
        line-height: 24.8px;
        text-align: center;
      }
    }
  }
}
