@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

$card-min-width: 250px;
$card-max-width: 350px;

.highlightedCard {
  min-width: 280px;
  margin-right: 25px;
  max-width: 320px;
  width: 100%;
  padding-bottom: 18px;

  .cabecalho {
    > h3 {
      font-size: 18px;
    }
  }

  .stateImage {
    width: 100%;
    border-radius: 15px;
    max-height: 280px;
    height: 27vh;
    max-width: 320px;
    object-fit: cover;
  }

  > a {
    text-decoration: none;
  }
  > p {
    margin-top: -5px;
    margin-bottom: 12px;
    color: #4d5354;
    font-weight: 300;
  }

  ul > li > a {
    color: $blue1;
    font-size: 16px;
    line-height: 24px;

    &:visited {
      color: $blue1;
    }
  }
}

.propertiesLinks {
  display: flex;
  flex-direction: column;
  a {
    color: #23355a;
  }
}

.subtitle {
  margin-bottom: 15px;
}
