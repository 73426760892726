@import '../../assets/scss/mixins.scss';
@import '../../assets/scss/variables.scss';

.container {
  max-width: 350px;
  width: 25%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  position: fixed;
  right: 30px;
  bottom: 4%;
  cursor: pointer;
  text-decoration: none;
  > div {
    width: 80%;
  }

  @include screen(max-md) {
    width: 90%;
    bottom: 25%;
    right: 12px;
  }
}
.message {
  padding: 8px;
  background: #24345a;
  border-radius: 4px;
  > p {
    color: #fff;
  }
  @include screen(max-md) {
    padding: 5px 8px;
  }
}
.circle {
  max-width: 35px;
  max-height: 36px;
  padding: 10px;
  border-radius: 50%;
  background: #24345a;
  margin-left: 0.5em;
}
.icon {
  height: 100%;
  width: 100%;
  cursor: pointer;
  color: #fff;
}
