@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--rosie-card-bg, $blue1);
  height: auto;
  padding: 2vh 2vw;
  border-bottom: 1px solid $line-color;
  color: $white-color;

  a {
    color: var(--primary-color, $primary-color);
    font-weight: bold;
  }

  span {
    color: var(--rosie-card-text, white);
  }

  .button {
    height: 30px;
    margin-left: 40px;
  }

  @include screen(max-sm) {
    display: none;
  }

  @media (min-width: 640px), (max-width: 900px) and (orientation: landscape) {
    display: flex;
  }
}
