.containerCard {
  width: 100%;
  background: #F9F9F9;
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .line {
    border: 1px solid #D9D9D9;
    width: 100%;
  }

  .cardInfo {
    width: 100%;
    display: flex;
    justify-content: space-between;

    strong {
      font-size: 16px;
      line-height: 24px;
      color: #101010;
    }

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #101010;
    }
  }

  .sac {
    margin-top: 13px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #6F7779;
  }
}