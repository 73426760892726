@import '../../assets/scss/variables.scss';

@import '../../assets/scss/mixins.scss';

.containerFaq {
  width: 100%;
  padding: 48px 0;
  margin-top: 72px;
  margin: 62px auto 62px;

  .mainTitle {
    text-align: center;
    font-size: $font-size-32;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 32px;
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;

    @include screen(max-md) {
      padding: 0 16px;
    }
  }
}
