@import '../../assets/scss/variables.scss';

.button {
  display: inline-flex;
  align-items: center;
  padding: 6px 18px;
  border-radius: 6px;
  font-size: $font-size-14;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  border: none;
  height: 37px;
  white-space: nowrap;
  justify-content: center;

  &:focus {
    outline: none;
  }

  > .right {
    margin-left: 10px;
    width: 23px;
    height: 23px;
  }
  > .left {
    margin-right: 10px;
    width: 23px;
    height: 23px;
  }
}

.containerLoading {
  display: flex;
  flex-direction: row;
  align-items: center;

  .textLoading {
    margin-left: 5px;
  }
}

.font-xsmall {
  font-size: $font-size-12;
}

.font-small {
  font-size: $font-size-14;
}

.font-medium {
  font-size: $font-size-16;
}

.direction-right {
  flex-direction: row-reverse;
}

.direction-left {
  flex-direction: row;
}

.paddingButton {
  padding: 6px 12px;
}

@mixin primaryColor {
  color: $primary-color;
}

@mixin darkColor {
  color: $lavender1;
}

@mixin whiteColor {
  color: $white-color;
}

@mixin redColor {
  background-color: var(--cta-primary-color, $tertiary-color);
  color: var(--cta-primary-text, white);
}

.primaryBackground {
  background-color: $primary-color;
  @include whiteColor;
}

.redBackground {
  @include redColor();
}

.darkBackground {
  background-color: $lavender1;
  @include whiteColor;
}

.greenBackground {
  background-color: $green-color;
  @include whiteColor;
}

.greenDarkBackground {
  background-color: $green-color;
  @include whiteColor;
}

.redBackground {
  background-color: var(--cta-primary-color, $tertiary-color);
  @include whiteColor;
}

.whiteBackground {
  background-color: $white-color;
  color: #666666;
  border: 1px solid #666666;
}

.primaryBorder {
  border: 1px solid $primary-color;
  @include primaryColor;
}

.primaryColor {
  fill: $primary-color;
}

.darkBorder {
  border: 1px solid $lavender1;
  @include darkColor;
}

.disabled {
  @extend .button;
  background-color: $gray3 !important;
  border: 1px solid $gray3;
  @include whiteColor;
  cursor: not-allowed;
}

.filled {
  @extend .button;
}

.outline {
  @extend .button;
  background-color: transparent;
}

.text {
  @extend .button;
  background-color: transparent;
  border: 0;
  padding: 0;
  outline: 0;
}

.bold {
  font-weight: bold;
}
