@import 'assets/scss/variables.scss';
@import 'assets/scss/mixins.scss';

:root {
  --myColor: black;
}

* {
  font-family: $font-family;
}

.wf-loading:not(.wf-inactive),
.wf-loading:not(.wf-inactive) * {
  color: transparent !important;
}

