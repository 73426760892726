@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';
@import '../PropertyCardValues/PropertyCardValues.module.scss';

$small-screen: 768px;
$image-size: 200px;

.card {
  display: inline-flex;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: $white-color;
  margin-bottom: 14px;
  width: 100%;
  // height: 200px;

  a {
    text-decoration: none;
  }

  .imageLink {
    min-width: $image-size;
    min-height: $image-size;
    max-width: $image-size;
    max-height: $image-size;
  }

  .image {
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin: 0;
    cursor: pointer;
    position: relative;
  }

  .image::after {
    -moz-box-shadow: inset 0 39px 35px 0px #0000147d;
    -webkit-box-shadow: inset 0 39px 35px 0px #0000147d;
    box-shadow: inset 0 39px 35px 0px #0000147d;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }

  > .information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 20px;
    min-width: calc(100% - #{$image-size} - #{$property-card-value-size});
    max-width: calc(100% - #{$image-size} - #{$property-card-value-size});

    .propertyTitle {
      font-size: $font-size-18;
      font-weight: 600;
      color: $blue1;
    }

    > .tags {
      margin-bottom: 2px;
      position: relative;
      display: flex;
      align-items: center;
    }

    > .features {
      display: flex;
      flex-wrap: wrap;
      margin-top: 5px;
      > div {
        margin-right: 6px;
      }
    }
  }

  .bankLogo {
    position: absolute;
    top: 4%;
    left: 4%;
    max-width: 160px;
    max-height: 60px;
    z-index: 1;
  }

  .mobileNavigate {
    display: none;
  }

  @include screen(max-md) {
    .mobileNavigate {
      display: flex;
      position: absolute;
      width: 91%;
      height: 515px;
    }

    flex-direction: column;
    width: auto;

    .tags {
      max-width: 300px;
      flex-wrap: wrap;
      display: flex;
    }

    .imageLink {
      width: 100%;
      max-width: 100%;
      height: 214px;
    }

    .image {
      border-bottom-left-radius: 0px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    > .information {
      border-bottom: 0.1px solid $line-color;
      padding-bottom: 10px;
      margin-bottom: 10px;
      min-width: none;
      max-width: none;
      width: auto;

      > .features {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
}

.ellipsis {
  font-size: $font-size-14;
  color: $black-color;
  overflow: hidden;
  text-overflow: ellipsis;
  // -webkit do not work on IE 11
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: initial;
  overflow-wrap: anywhere;

  // only internet explorer and edge
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    line-height: 1.5em;
    height: 3em;
  }

  @include screen(max-md) {
    margin-top: 0.5em;
  }
}

.link {
  text-decoration: none;
  color: $blue1;
  font-weight: bold;
  font-size: $font-size-18;
}

.sold {
  & span {
    background-color: $tertiary-color;
    color: $white-color;
    width: 93%;
    font-size: $font-size-14;
    font-weight: bold;
    letter-spacing: 0.9px;
  }

  & div {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: absolute;
    display: flex;
    top: 11%;
    right: -29%;
    padding: 5px 0;
    text-align: center;
    transform: rotate(40deg);
    height: 25px;
    z-index: 1;

    @include screen(xs) {
      top: 8%;
      right: -23%;
      width: 80%;
    }

    @include screen(max-sm) {
      width: 80%;
    }

    @include screen(min-md) {
      width: 80%;
    }

    @include screen(max-md) {
      width: 75%;
      right: -23%;
    }

    @include screen(min-lg) {
      width: 100%;
    }
  }
}

.resumeAddress {
  color: $blue1;
  font-size: $font-size-14;
  margin-bottom: 2px;
  letter-spacing: 0.18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
}
