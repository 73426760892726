@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.disclaimerModal {
  z-index: 13;
  @include flexBox(column);
  height: auto;
  width: 50vw;

  @include screen(max-sm) {
    width: 75vw;
  }

  @include screen(xs) {
    padding: 15px;
  }

  > p {
    text-align: center;
  }

  > * {
    margin-bottom: 3.5%;

    @include screen(max-sm) {
      margin-bottom: 5%;
    }
  }
}

.disclaimerBackdrop {
  z-index: 12;
}
