@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.AsteriskExplain {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 90%;
  padding: 1em;
  margin: 1em 0 0 0;
  text-align: right;
  background-color: $asterisk-background-color;

  @include screen(max-md) {
    background-color: $lavender0;
  }
}

.Fenab {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0 0;
  color: #000;
  grid-gap: 15px;
  gap: 15px;

  > p {
    font-size: 14px;
    text-align: center;
  }

  >button {
    all: unset;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    > p {
      &:first-child{
        text-decoration: underline;
      }
    }
  }

  .ModalContent{
    margin-top: 20px;
  }
}
