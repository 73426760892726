@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.container {
  height: auto;

  > div {
    > div {
      border-color: transparent !important;
      margin-bottom: 0px !important;
      padding: 0 !important;
      > div {
        flex-direction: row-reverse;
        align-items: center;
        gap: 8px;

        > div {
          color: #101010;
        }

        > div {
          &:last-child {
            border-color: $gray3 !important;
            padding: 0 !important;
            width: 16px !important;
            height: 16px !important;

            > div {
              width: 100% !important;
              height: 100% !important;
              background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 42" width="10" height="10" fill="%23ffff"><path d="M20.687 38.332a5.308 5.308 0 0 1-7.505 0L1.554 26.704A5.306 5.306 0 1 1 9.059 19.2l6.928 6.927a1.344 1.344 0 0 0 1.896 0L36.642 7.368a5.308 5.308 0 0 1 7.505 7.504l-23.46 23.46z"/></svg>') !important;
              background-repeat: no-repeat !important;
              background-position: center !important;
            }
          }
        }
      }
    }
  }
}
.textStrong {
  font-size: 16px;
}
