@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.steps {
  position: relative;

  margin-top: -10px !important;
}

.container {
  div[class*='steps-item-title'] {
    line-height: 20px;
    font-weight: 700;
    font-family: $font-family;
    font-size: $font-size-16;
    color: $blue1;
    margin-left: 24px;
  }
  div[class*='steps-item-description'] {
    font-size: $font-size-14;
    color: $secondary-color;
    line-height: 18px;
    font-family: $font-family;
    margin-bottom: 24px;
    margin-left: 24px;
  }
  div[class*='steps-item-icon'] {
    width: 12px;
    height: 12px;
    background-color: $primary-color;
    border-color: $primary-color;
  }
  div[class*='steps-item-tail'] {
    padding: 0px;
    left: 4.8px;

    &::after {
      background-color: $primary-color;
      width: 2px;
    }
  }
}

.icon {
  div[class*='steps-item-icon'] {
    width: 20px;
    height: 20px;
    margin-left: -4.5px;
  }
}

.checkIcon {
  margin-bottom: 2px;
}
