@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.notificationFooterContainer {
  @include flexBox(column, space-between);
  width: 95%;
  margin-top: 5%;

  > p {
    text-align: center;
  }

  .button {
    margin-top: 8px;
    width: 200px;
  }
}
