@import '../../assets/scss/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  > .close {
    position: absolute;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;
    top: 8px;
    right: 10%;
    z-index: 1;
    color: var(--primary-color, #7896ff);

    &:disabled {
      display: none;
    }
  }

  .input {
    height: 40px;

    svg {
      cursor: pointer;
      fill: var(--primary-color, #7896ff);
    }
  }
}

svg {
  cursor: pointer;
}
