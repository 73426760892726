@import '../../assets/scss/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
}

.itemContainer {
  display: flex;
  flex-direction: row;
}

.data {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.document {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.margin {
  margin-top: 10px;
}
