@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.alertSuccessModal {
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  align-items: center;

  > .header {
    display: flex;
    align-items: flex-start;
    width: 80%;

    > .img {
      margin-right: 20px;
      padding: 0;

      .botImage {
        border-radius: 50%;
        border: 3px solid $white-color;
        box-shadow: 0px 3px 4px 1px rgb(160, 160, 160);
      }
    }
    > .infoHeader {
      margin-top: 20px;
    }
  }
  > .footer {
    display: inline-flex;
    align-items: center;
    margin-top: 20px;

    .button {
      width: 182px;
      margin: 0px 5px;
    }
  }

  > .infoBody {
    text-align: center;
    margin-top: 15px;
  }

  @include screen(max-sm) {
    margin: 5% 0 20% 0;

    > .header {
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;

      > .img {
        margin-right: 0;

        img {
          width: 70%;
        }
      }
    }
  }

  @include screen(xs) {
    > .footer {
      flex-direction: column;
      align-items: center;

      .button {
        margin: 5px;
      }
    }
  }
}
