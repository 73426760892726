@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';
@import '../../components/HighlightedStateCard/HighlightedStateCard.module.scss';

.sectionContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 16px;
  margin-top: 20px;
  max-width: 1400px;
  margin: 0 auto;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 1440px) {
    padding: 0 32px;
  }
}
