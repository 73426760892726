@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.container {
  display: flex;
  // width: 340px;
  flex-direction: column;
  background-color: var(--rosie-card-bg, $blue1);
  border-radius: 8px;

  // @include screen(min-md) {
  //   width: 510px;
  // }

  .figure {
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: center;

    .imgContainer {
      display: flex;
      justify-content: center;
      align-content: center;
      margin-top: -$notification-box-rosie-size / 2;
      width: $notification-box-rosie-size - 2px;
      height: $notification-box-rosie-size - 2px;
      border: 5px solid $white-color;
      border-radius: 50%;

      .img {
        width: $notification-box-rosie-size;
        height: $notification-box-rosie-size;
      }
    }
  }

  .body {
    @include flexBox(column, space-between);
    align-items: center;
    width: 100%;

    h2 {
      color: var(--rosie-card-text, white);
    }

    > p {
      margin-top: 15px;
    }

    .info {
      @include flexBox(column, space-between);
      text-align: center;
      padding: 15px;
      margin: 0% 10%;

      p {
        color: var(--rosie-card-text, white);
      }

      .emailInput {
        width: 100%;
        height: 40px;
        margin-top: 8px;

        label {
          margin: 0;
        }

        span {
          font-size: $font-size-14;
        }

        input {
          border-radius: 5px;
        }
      }

      .buttonSubmit {
        width: 160px;
        height: 40px;
        border-radius: 8px;
        margin-top: 35px;
        margin-bottom: 15px;
      }

      @include screen(xs) {
        margin: 0% 1%;
      }
    }
  }
}
