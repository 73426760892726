@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.wrapper {
  color: $blue1;
  font-size: $font-size-14;
  letter-spacing: 0.18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;

  @include screen(xs) {
    p {
      -webkit-line-clamp: 2;
    }
  }
}
