.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.scoreContainer {
  display: flex;
  flex-direction: row;
}

.left {
  flex: 1;
}

.right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.updatedAt {
  text-align: right;
}
