@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.howToBecomeSupplier {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 72px;

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
  }

  .mainTitle {
    text-align: center;
    line-height: 33px;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 48px;

    @include screen(max-md) {
      padding: 0 16px;
      margin-bottom: 24px;
    }
    @include screen(xxl) {
      margin-top: 150px;
    }
    @media (min-width: 2700px) {
      margin-top: 250px;
    }
  }
  .container {
    box-sizing: border-box;
    height: auto;
    max-width: 1200px;
    margin: 0 auto;
    @include flexBox(row, space-between);
    gap: 56px;

    @include screen(max-md) {
      width: 100%;
      background-image: none;
      overflow-x: scroll;
      padding: 16px;
      gap: 0;
    }

    > .card {
      width: 300px;
      margin: 0 auto;
      min-height: 440px;
      height: 100%;
      @include flexBox(column);

      @include screen(max-md) {
        width: 100%;
        background-color: #f8f8f8;
        margin-right: 16px;
        min-width: 300px;
        max-height: 440px;
        border-radius: 16px;
        padding: 8px;

        .card:last-child {
          margin-right: 0;
        }
      }

      > figure {
        margin-bottom: 36px;
        @include screen(max-sm) {
          margin-bottom: 0;
        }
        > img {
          width: 250px;
          height: 250px;
        }
      }
      > h3 {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 24px;
        @include screen(max-sm) {
          margin-bottom: 12px;
        }
      }
      > p {
        text-align: center;
        line-height: 24px;
        max-width: 260px;
        min-height: 100px;
      }
    }
  }

  .toBecomeButton {
    background-color: var(--cta-primary-color, $tertiary-color);
    color: var(--cta-primary-text, white);
    height: 64px;
    width: 394px;
    margin-top: 32px;
    border-radius: 8px;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
    font-family: Titillium Web;
    font-style: normal;

    @include screen(xs) {
      width: 294px;
      height: 54px;

      font-size: 16px;
      line-height: 24px;
    }
  }
}
