@import '../../assets/scss/variables.scss';

.selectorContainer {
  height: 40px;
  display: flex;

  .wrapper {
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
    border-radius: 6px;
    padding: 4px;
    border: solid 1px $gray2;
    background-color: $white-color;

    .button {
      padding: 0px 16px;
      height: 34px;
      margin-top: -1px;
      border-radius: 0;
      font-weight: bold;
      line-height: 34px;
      outline: 0;

      > p {
        font-size: 11px;
      }
    }

    .button.disabled {
      background-color: transparent;
      color: $lavender1;
    }
  }

  .tabs > button:not(:last-child) {
    margin-right: 8px;
    position: relative;

    &::after {
      border-right: 2px solid $gray2;
      bottom: 0;
      content: '';
      display: block;
      position: absolute;
      right: -5px;
      top: 0;
    }
  }
}
