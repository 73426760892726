@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

@include screen(xs) {
  [class='wrappedModal'] {
    padding: 1.2em;
  }

  @supports (-webkit-touch-callout: none) {
    .modalClass {
      > span {
        transform: translateY(15px);
      }
    }
  }
}
.modalClass {
  box-sizing: border-box;
  width: 45vw;
  max-width: 545px;
  height: 90vh;
  max-height: 750px;
  border-radius: 16px;
  padding: 0;
  overflow-y: hidden;

  &.success {
    box-sizing: border-box;
    width: 100%;
    overflow-y: hidden;
    max-width: 548px;
    height: 100%;
    max-height: 370px;
  }

  @media (max-width: 1280px) {
    width: 100%;
  }
  @include screen(xs) {
    width: 100vw;
    height: 100vh;
  }
}
.simulatorModal {
  display: flex;
  flex-direction: column;
  margin: 9px 14px;
  zoom: 0.9;

  // for IE and Edge
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    zoom: 1;
  }

  @include screen(xs) {
    padding-bottom: 20%;
    @supports (-webkit-touch-callout: none) {
      margin-top: 29px;
    }
  }

  .top {
    @include flexBox();
    margin-bottom: 10%;

    .title {
      color: #666666;
      font-weight: 400;
      line-height: 26px;
    }
  }

  > .header {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    > .info {
      display: flex;
      flex-direction: column;

      .contactText {
        margin-top: -2px;
      }

      .description {
        margin-top: 14px;
      }
    }
  }

  > .main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 29px;
    position: relative;

    .modalLabel {
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      color: #666666;
    }
    > .inputName {
      width: 100%;
      margin-top: 16px;
    }

    > .inputPhone,
    > .inputEmail {
      margin-top: 3px;
    }

    > .selectPeriod,
    > .selectDay {
      margin-top: 6px;
    }

    > .inputPhone,
    > .selectPeriod {
      width: 50%;
      padding-left: 3%;
      box-sizing: border-box;
    }

    > .inputEmail,
    > .selectDay {
      width: 50%;
      padding-right: 2%;
      box-sizing: border-box;
    }
    > .inputSubject {
      width: 100%;
      margin-top: 16px;
      label {
        p,
        select {
          color: #666666;
        }
      }
    }

    input {
      height: 40px;
    }

    > .inputName,
    > .inputPhone {
      position: relative;

      > span {
        position: absolute;
        bottom: -16px;
        right: 0;
        font-size: $font-size-12;
      }
    }

    > .inputEmail {
      position: relative;

      > span {
        position: absolute;
        bottom: -16px;
        right: 11px;
        font-size: $font-size-12;
      }
    }
    .valuesContainer {
      margin-top: 1rem;
      background-color: #f4f4f4;
      border-radius: 8px;
      width: 100%;
      padding: 16px 24px;

      p {
        @include flexBox(row, space-between);
        margin-top: 4px;
        &:first-child {
          margin-top: 0;
        }
      }
    }

    @include screen(max-sm) {
      .selectPeriod {
        width: 100%;
        padding-left: 0;
      }
      .selectDay {
        width: 100%;
      }
    }
    .warning {
      position: absolute;
      bottom: -5%;
      left: 0;
      color: $tertiary-color;
      font-size: $font-size-14;

      @include screen(xs) {
        position: static;
        max-width: 40ch;
      }
    }
  }

  > .footer {
    justify-content: center;
    display: flex;
    margin-top: 2%;

    > .button {
      width: 100%;
      margin: 5px;
      border-radius: 8px;
      height: 7vh;
    }
  }
}
