@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.lightBlueColor {
  background-color: var(--cta-secondary-color, $lavender1);
  color: var(--cta-secondary-text, $white-color);
  border-radius: 8px;
}

.lightResaleColor {
  background-color: var(--cta-primary-color, $tertiary-color);
  color: var(--cta-primary-text, $white-color);
  border-radius: 8px;
}

.largeButton {
  gap: 5px;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 8px;
  height: 40px;
  background-color: var(--cta-primary-color, $tertiary-color);
  color: var(--cta-primary-text, $white-color);

  @include screen(max-md) {
    width: 98%;
  }
}
