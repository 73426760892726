@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .featuredFeatures {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;

    use {
      fill: var(--primary-color, #7896ff);
    }
    p {
      color: var(--text-color, $blue1);
    }

    @include screen(xs) {
      > div:nth-last-child(2),
      :nth-last-child(3) {
        display: none;
      }
    }
  }

  .featuresStart {
    justify-content: center;
  }

  .details {
    transition: height 2s linear;
    overflow: hidden;
    text-align: center;
    margin-top: 24px;

    .allFeatures {
      border-radius: 5px;
      background-color: $background-color;
      flex-wrap: wrap;
      justify-content: space-around;
      display: flex;
      flex-direction: row;
      margin-bottom: 28px;
      padding: 26px;

      .featureItem {
        flex: 1 1 33%;
        align-self: flex-start;
      }

      [class*='MoreFeatures_text'] {
        p {
          color: var(--text-color, #262626);
        }
      }
    }

    summary::-webkit-details-marker {
      display: none;
    }
    > summary {
      text-align: center;
    }
  }

  @include screen(xs) {
    .details {
      .allFeatures {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
