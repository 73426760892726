@import '../../assets/scss/mixins.scss';
@import '../../assets/scss/variables.scss';
@import '../../components/Header/Header.module.scss';

@mixin heroSubContainer() {
  width: 90%;

  @include screen(min-sm) {
    width: 80%;
  }

  @include screen(min-md) {
    width: 50%;
  }

  @include screen(md) {
    width: 42%;
  }
}

.heroContainer {
  @include flexBox(column);
  width: 100%;
  height: calc(100vh - 1.5 * #{$header-mobile-height});
  max-height: 500px;
  position: relative;

  .campaignImage {
    width: 100%;
    height: 100%;
    position: relative;
  }

  > .heroImage {
    @include heroSubContainer();
  }

  @include screen(xxl) {
    max-height: 600px;
  }

  @include screen(min-md) {
    @include flexBox(row, space-around);
    height: calc(100vh - 3 * #{$header-height});

    @include screen(xxl) {
      justify-content: center;
      gap: 120px;
    }

    > .heroImage {
      max-width: 594px;

      // @include screen(min-lg) {
      //   margin-top: 10%;
      // }

      @include screen(xxl) {
        max-width: 800px;
      }
    }
  }

  &.hasCampaign {
    max-height: none;
    background: transparent;
    height: 100%;
    position: relative;

    img {
      max-height: none;
      cursor: pointer;
    }

    @include screen(min-md) {
      background: transparent;
      height: 100%;
    }

    div[class*='magic-dots'] {
      width: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute !important;
      top: 87%;

      > ul {
        > li {
          button {
            &::before {
              opacity: unset;
              color: $lavender0;
            }
          }
        }

        > li[class*='active'] {
          button {
            &::before {
              color: var(--primary-color, #7896ff);
            }
          }
        }
      }
    }

    div[class*='slick-slider'] {
      width: 100%;
      height: 100%;
    }
  }

  .countDown {
    position: absolute;
    bottom: 22%;
    left: 24%;

    span[class*='title'],
    span[class*='subTitle'] {
      color: #ffffff !important;
    }

    @include screen(xxs) {
      left: 19% !important;
      top: 28% !important;
    }

    @include screen(xs) {
      top: 22%;
    }

    @include screen(sm) {
      top: 24%;
      left: 34%;
    }

    @media (min-width: 768px) and (max-width: 1299px) {
      left: 23%;
      bottom: 28%;
    }
  }
}
