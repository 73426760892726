@import '../../assets/scss/mixins.scss';
@import '../../assets/scss/variables.scss';
@import '../../containers/HeroSectionContainer/HeroSectionContainer.module.scss';

.searchBox {
  @include flexBox(column, space-around, flex-start);
  @include heroSubContainer();
  margin-top: -15%;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 14px;
  height: 256px;
  border-radius: 15px;

  @include screen(min-md) {
    // background: linear-gradient(271.8deg, #003e7e -13.24%, #69a2c2 84.7%);
    margin-top: 0;
    padding: 32px;
    max-height: 220px;
    max-width: 350px;
    height: 70%;
  }

  @include screen(xxl) {
    max-height: 300px;
    max-width: 500px;
    padding: 44px;
  }

  &.hasCampaign {
    position: absolute;
    bottom: 8%;

    @include screen(xxs) {
      bottom: 6%;
    }
    @include screen(min-md) {
      bottom: initial;
      right: 2%;
    }

    @include screen(xxl) {
      padding: 38px;
    }
  }

  > h1 {
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 16px;

    @include screen(xxl) {
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 16px;
    }
  }

  > p {
    font-weight: 300;
    font-style: normal;
    line-height: 27px;
    margin-bottom: 24px;

    @include screen(xxl) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  span[class*='adornment'] {
    top: unset !important;
  }
}
