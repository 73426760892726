@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.container {
  box-sizing: border-box;
  width: 100%;
  height: 28px;
  max-height: 28px;
  background-color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 20px;

  img {
    width: 18px;
    height: 18px;
  }

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }

  &.aniversarioResale {
    background-color: #23345a;

    span {
      color: $white-color;
      font-weight: 600;
    }
  }
}
