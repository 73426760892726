@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.superBannerContainer {
  width: 100%;
  z-index: 5;

  button[class*='react-multiple-carousel__arrow'] {
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    background: rgba(0,0,0,.5) !important;
    min-width: 43px;
    min-height: 43px;
    opacity: 1;
    box-shadow: none;

    cursor: pointer;
    &::before {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 16px;
    }
    &:hover {
      background: #111111 !important;
    }
  }
}

.imageClear {
  line-height: 0;
  position: relative;
  transition: all 0.9s;
  cursor: pointer;
  z-index: 2;
  top: 0px;
}

.imageClearHidden {
  line-height: 0;
  position: relative;
  transition: all 0.7s;
  cursor: pointer;
  opacity: 0;
  height: 0;
  z-index: 2;
  top: 0px;
}

.bannerHidden {
  position: sticky;
  top: 0px;
  z-index: 2;
  transition: all 0.9s;
  height: 0;
  width: 100%;
}

.banner {
  opacity: 1;
  transition: all 0.5s;

  width: 100%;
  top: 0px;
  z-index: 2;
}
