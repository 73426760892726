@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.carouselCard {
  margin-top: 32px;
  min-height: 500px;
  margin-right: 8%;
  margin-bottom: 13%;

  @include screen(xs) {
    margin-right: unset;
  }
}

button[class*='react-multiple-carousel__arrow'] {
  box-shadow: unset;
  color: #fff9f9 !important;
  margin: 0 auto;
  transition: all 0.3s ease 0s;
  top: 44%;
  background-color: #ffffff !important;
  min-width: unset;
  min-height: unset;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 1;
  box-shadow: 0px 15px 20px rgba(197, 197, 197, 0.4);

  &::before {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $black-color;
    font-size: 12px;
  }
  &:hover {
    color: #111111 !important;
  }
}
