@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.sectionHavePartnership {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 48px;

  @include screen(min-md) {
    padding: 0 32px;
  }

  h1 {
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    margin: 24px 0;
  }

  .list {
    display: flex;

    @include screen(max-md) {
      flex-direction: column;
      align-items: center;
    }

    article:nth-child(2) {
      margin: 0 52px;

      @include screen(max-md) {
        margin: 52px 0;
      }
    }
  }
}
