@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';

.container {
  .chart {
    margin: 22px 10px 12px 10px;

    @include screen(max-md) {
      margin: 10px 0;
    }
  }
}
