@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.wrapper {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 8vh;
  @media (max-width: 1024px) and (min-width: 481px) {
    margin-bottom: 235px;
  }

  > img {
    width: 65px;
    height: 65px;
  }

  > span {
    background-color: $whatsapp-green-color;
    padding: 8px 12px;
    border-radius: 10px;
    color: $white-color;
    margin-right: 12px;

    max-width: 160px;
    font-size: $font-size-14;

    &::after {
      content: ' ';
      position: absolute;
      top: 25%;
      left: 190px;
      margin-left: -7px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent transparent transparent $whatsapp-green-color;
    }
  }

  @include screen(xs) {
    right: 10px;
    bottom: 200px;
  }
}
