@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.swiperContainer {
  width: 100%;
  max-width: 1388px;
  overflow: hidden;
  list-style-type: none;
  margin: auto;
  position: relative;

  @include screen(max-sm) {
    margin-bottom: 56px;
  }
}

.slider {
  list-style-type: none;
  display: flex;
  text-align: center;
  flex-direction: row;
  padding-top: 20px;
}

.timestamp {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  font-weight: 100;
}

@include screen(xs) {
  .timestamp {
    max-width: 27vw;

    img {
      max-width: 27vw;
    }

    &.portoSeguro {
      img {
        max-width: 44vw;
      }
    }
  }
}

@include screen(sm) {
  .timestamp {
    max-width: 24vw;

    img {
      max-width: 24vw;
    }

    &.portoSeguro {
      img {
        max-width: 40vw;
      }
    }
  }
}

@include screen(md) {
  .timestamp {
    max-width: 14vw;

    img {
      max-width: 14vw;
    }

    &.portoSeguro {
      img {
        max-width: 24vw;
      }
    }
  }
}

@include screen(lg) {
  .timestamp {
    max-width: 14vw;

    img {
      max-width: 14vw;
    }

    &.portoSeguro {
      img {
        max-width: 24vw;
      }
    }
  }
}

@include screen(xl) {
  .timestamp {
    max-width: 10vw;

    img {
      max-width: 10vw;
    }

    &.portoSeguro {
      img {
        max-width: 20vw;
      }
    }

    &.bancoDaycoval {
      img {
        max-width: 16vw;
      }
    }
  }
}

.swiperButton {
  all: unset;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  outline: inherit;
}

.buttonContainer {
  display: flex;
  width: 100%;
  max-width: 1388px;
  justify-content: space-between;
  position: absolute;
  margin-top: 32px;

  .arrow {
    width: 22px;
  }
}

.button {
  z-index: 1;
  display: flex;
  align-items: center;
  text-decoration: none;
  border: 0;
  background: none;
  cursor: pointer;
  margin: 0px;

  &:focus {
    outline: none;
  }

  @include screen(xs) {
    margin: 0px 5px;
  }
}
