@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.containerDepoiments {
  margin-top: 72px;
  .titleDepoiments {
    color: $blue1;
    margin-bottom: 32px;
    text-align: center;
  }

  .depoiments {
    @include screen(xxl) {
      > div {
        justify-content: center;
      }
    }

    margin-left: 4%;
    @include screen(xxs) {
      margin-left: unset;
    }
    @include screen(xs) {
      > div {
        > ul {
          padding: 0px 18px;
        }
      }
    }
  }
}
