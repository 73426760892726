@import '../../assets/scss/mixins.scss';

.activeFilterTags {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  overflow-x: scroll;
  margin-bottom: 10px;
  // margin-top: 20px; // TODO: fix toggle

  @include screen(max-sm) {
    width: 100%;
    padding-left: 10px;
    justify-content: flex-start;
  }

  &.carrouselMode {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}
