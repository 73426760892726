@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

@include screen(xs) {
  @supports (-webkit-touch-callout: none) {
    .modalClass {
      > span {
        transform: translateY(15px);
      }
    }
  }
}

.messageModal {
  display: flex;
  flex-direction: column;
  margin: 9px 14px;
  zoom: 1.025;

  // for IE and Edge
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    zoom: 1;
  }

  @include screen(xs) {
    padding-bottom: 40%;
    @supports (-webkit-touch-callout: none) {
      margin-top: 29px;
    }
  }

  > .header {
    display: flex;
    position: relative;
    width: 100%;

    > .info {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;

      h1 {
        color: $secondary-color;
        font-weight: 700;
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 16px;
      }

      .contactText {
        margin-top: -2px;
        padding: 0 69px;

        @include screen(xs) {
          padding: 0;
        }
      }

      .collapse {
        color: var(--primary-color, $primary-color);

        .upArrow {
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          transform: rotate(90deg);
          margin-top: 6px;
        }

        .downArrow {
          -webkit-transform: rotate(270deg);
          -moz-transform: rotate(270deg);
          -o-transform: rotate(270deg);
          -ms-transform: rotate(270deg);
          transform: rotate(270deg);
        }
      }

      .collapsedDiv {
        height: 0px;
        overflow: hidden;

        .financialData {
          padding: 0 20px;
        }
      }

      .collapsedDiv.expand {
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 10px;
        margin: 20px 0;
        border-top: 1px solid #dadadf;
        border-bottom: 1px solid #dadadf;
      }
    }

    > .logo {
      margin: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  > .main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 16px;
    width: 100%;

    > .inputName {
      width: 100%;
      margin-top: 17px;
    }

    > .checkbox {
      justify-content: flex-end;
      margin-top: 11px;
      padding: 0;
      margin-bottom: 0px;

      input {
        height: auto;
      }
    }

    > .inputPhone,
    > .inputConfirmPassword {
      width: 50%;
      padding-left: 3%;
      box-sizing: border-box;
    }

    > .inputEmail,
    > .inputPassword {
      width: 50%;
      padding-right: 2%;
      box-sizing: border-box;
    }

    .modalLabel {
      margin-bottom: 3px;
    }

    input {
      height: 40px;
    }

    > .inputName,
    > .inputPhone,
    > .inputConfirmPassword {
      position: relative;

      > span {
        position: absolute;
        bottom: -16px;
        right: 0;
        font-size: $font-size-12;
      }
    }

    > .inputEmail,
    > .inputPassword {
      position: relative;

      > span {
        position: absolute;
        bottom: -16px;
        right: 0;
        font-size: $font-size-12;
      }
    }

    .recoverPasswordButton {
      color: $blue1;
      font-weight: normal;
      height: auto;
      margin-top: 5px;
    }

    .recoverPasswordContainer {
      width: 100%;
    }

    > .beforeFooter {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-top: 17px;

      strong {
        font-weight: bold;
      }
    }
  }

  > .footer {
    justify-content: center;
    display: flex;
    flex-direction: column;

    .reCAPTCHA {
      max-width: 240px;
      width: 50%;
      margin: 0 auto 15px auto;
      @media (max-width: 490px) and (min-width: 290px) {
        width: 92%;
      }
      > div > div > div {
        max-width: 0;
      }
    }

    .submit {
      width: 100%;
      height: 40px;
      border-radius: 8px;
    }

    .link {
      text-decoration: none;
      color: var(--primary-color,$primary-color);
      font-size: $font-size-14;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.strong {
  strong {
    font-weight: bold;
  }
}

.pagImovel {
  padding: 21px 24px;
  border-radius: 2px;
  background-color: $gray8;
  margin-bottom: 15px;

  > p {
    text-align: center;
    margin: 0 auto;
  }

  .pagimovelLogo {
    height: 28px;
    width: 120px;
  }
}
