@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.amountResults {
  white-space: normal;
  word-break: break-word;
  color: $black-color;

  @include screen(max-md) {
    margin: 10px 0;
  }
}
