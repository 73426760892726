@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

$property-card-value-size: 280px;

.values {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  width: $property-card-value-size;
  max-width: $property-card-value-size;

  border-left: 1px solid $lavender0;

  > .prices {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-bottom: 5px;
  }

  .buttonOpportunity > .button {
    width: 100%;
  }
}

@include screen(max-md) {
  .values {
    max-width: none;
    width: 90%;
    align-self: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 0px;
    padding-bottom: 0px;
    border: none;

    > .prices {
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;

      div {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        p {
          font-size: $font-size-16;
        }
      }
    }

    > .buttonOpportunity {
      display: none;
    }
  }
}
