@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

@mixin sizes {
  max-height: 427px;
  max-width: 300px;
  min-height: 427px;
  min-width: 300px;
}

.cardContainer {
  @include sizes();
  margin-right: 24px;
  border-radius: 12px;
  text-decoration: none;
  background-color: $white-color;

  &:last-child {
    margin-right: 0;
  }
  .card {
    @include sizes();
    @include flexBox(column, flex-start, flex-start);
    width: 100%;
    border-radius: 12px;
    border: 1px solid #e0e0e0;

    figure {
      width: 100%;
      max-height: 240px;

      img {
        border-radius: 12px 12px 0 0;
        width: 100%;
        max-height: 200px;
        object-fit: cover;
        height: 200px;
      }
    }
    .info {
      padding: 12px 16px;

      h3 {
        color: $secondary-color;
        font-weight: 700;
        line-height: 28px;
        min-height: 140px;
      }
      p {
        color: #666666;
      }
    }
  }
}
