@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';


.container {
	display: flex;
	flex-direction: column;
	padding: 0;
	height: 100%;
		
	@include screen(xs){
		padding: 0;
	}

	@include screen(xs){
		margin: -3em 0;

	}

	.headerbb {
    width: 100%;
    z-index: 3;
    min-height: 88px;
    background-image: url('../../../assets/images/background-modalBB.png');
    background-repeat: no-repeat;
		background-size: cover;
	}
	.maxWidth {
		max-width: 400px;
	}
	.successContainer{
		@include flexBox(column,);
		height: 100%;
		padding: 20px;

		> h2 {
			margin-top: 1rem;
			font-weight: 700;
			text-align: center;
	
		}
		> p {
			text-align: center;
			margin-top: 1rem;
		}
	
		button {
			margin-top: auto;
			max-width: 280px;
			width: 100%;
			margin-top: 1rem;
			height: 52px;
			color: $white-color;
			border-radius: 8px;
			background-color: #FB4E5F;
			border-color: #FB4E5F;
		}
}
}