@import '../../assets/scss/variables.scss';

.text {
  margin: 0px;
  white-space: normal;
  word-break: break-word;
}

.alignCenter {
  text-align: center;
}

.primaryColor {
  color: var(--primary-color);
}

.darkBlueColor {
  color: var(--text-color);
}

.whiteColor {
  color: $white-color;
}

.lightBlueColor {
  color: $lavender1;
}

.blackColor {
  color: $black-color;
}

.resaleGreenColor {
  color: $green-color;
}

.font-xxsmall {
  font-size: $font-size-10;
}

.font-xsmall {
  font-size: $font-size-12;
}

.grayColor {
  color: $gray4;
}

.grayColorLigth {
  color: $gray10;
}

.font-small {
  font-size: $font-size-14;
}

.font-medium {
  font-size: $font-size-16;
}

.font-regular {
  font-size: $font-size-18;
}

.font-large {
  font-size: $font-size-20;
}

.font-xlarge {
  font-size: $font-size-24;
}

.bold {
  font-weight: 600;
}
