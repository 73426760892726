@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.card {
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #f9f9f9;
  @include flexBox(column, flex-start);
  max-height: 330px;
  padding: 24px 32px 0;

  @include screen(lg) {
    &:last-child {
      margin-top: 32px;
    }
  }

  @include screen(max-md) {
    margin-top: 60px;
  }

  .icon {
    @include flexBox(row, center);
    position: absolute;
    border-radius: 50%;
    background-color: $primary-color;
    text-align: center;
    width: 56px;
    height: 56px;
    top: -28px;

    > .iconImg {
      width: 56px;
      height: 56px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  > p {
    line-height: 28px;
    min-height: 170px;
    max-width: 30ch;
    margin-top: 8%;
    margin-bottom: 20px;
  }

  > footer {
    width: 100%;
    position: relative;
    margin-top: auto;
    bottom: 5px;
    .typeContainer {
      @include flexBox();
      width: 100%;
      position: relative;

      > hr {
        width: 35%;
      }

      > p {
        width: 25%;
        margin: 0 5%;
      }
    }
  }
}
