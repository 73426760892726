@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.reasonsToBePartner {
  width: 100%;
  position: relative;
  margin-top: 72px;
  padding: 0 2px;

  @include screen(min-md) {
    padding: 0 28px;
  }

  h1 {
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 32px;
  }

  .iconContainer {
    margin: 0 8px 0 8px;
    height: 208px;
    max-height: 208px;
    width: 208px;
    max-width: 208px;
    min-width: 208px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: $gray11;
    gap: 16px;

    > svg {
      width: 64px;
      height: 64px;
    }

    .fill {
      path {
        fill: var(--primary-color);
      }
    }

    .stroke {
      path,
      ellipse {
        stroke: var(--primary-color);
      }
      rect {
        fill: var(--primary-color);
      }
    }

    .strokeFill{
      path {
        stroke: var(--primary-color);

        &:not(:first-child){
          fill: var(--primary-color);
        }
      }
      rect {
        fill: var(--primary-color);
      }

    }

    > h2 {
      text-align: center;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      width: 80%;
    }
  }

  .bePartnerButton {
    background-color: var(--cta-primary-color, $tertiary-color);
    color: var(--cta-primary-text, white);
    width: 302px;
    height: 44px;
    border-radius: 8px;
    margin-top: 36px;
  }
}

.list {
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
  max-width: 100vw;
  padding: 0 1%;

  > button {
    margin: 0 -3% !important;
    background-color: white !important;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 15px 20px rgba(197, 197, 197, 0.4);
    color: #333 !important;
    margin: 0 auto;
    transition: all 0.3s ease 0s;
    top: 35%;

    &::before {
      color: #888;
    }
    &:hover {
      background-color: #f4f4f4 !important;
    }
  }
}
