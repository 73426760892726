@import '../../assets/scss/variables.scss';

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.value {
  font-size: $font-size-16;
}

.blackout {
  color: $line-color !important;
}

.strike {
  text-decoration: line-through;
}

.campaignAppraise {
  align-items: center;
  width: 100%;

  > p {
    font-size: $font-size-14;
  }
}
