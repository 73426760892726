@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.contactsList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  justify-content: center;
  max-width: 1180px;
  border: solid 1px $lavender0;
  padding: 34px 0;
  margin: 0 auto;

  .section {
    display: flex;
    flex-direction: column;
    border: 1px solid #dadadf;
    border-top: 0px;
    border-left: 0px;
    border-bottom: 0px;
    height: 110px;
    align-items: center;

    h2 {
      font-weight: bold;
      width: 128px;
    }

    .textWrapper {
      display: flex;
      flex-direction: column;
    }

    .socialNetworks {
      display: inline-flex;
      height: 32px;
      justify-content: flex-end;

      .linkNetwork {
        width: 32px;
      }
    }
  }

  .section.last {
    border-right: 0px;
  }
}

@include screen(md) {
  .contactsList {
    margin: 0 25px;
    grid-template-columns: 1fr 1fr;

    .section {
      > div {
        width: 195px;
      }
    }

    .section.second {
      border: 0;
    }
  }
}

@include screen(max-sm) {
  .contactsList {
    grid-template-columns: 1fr;
    padding: 10px 0;
    border-left: 0px;
    border-right: 0px;

    .section {
      border: 0;
      height: auto;
      margin: 10px 0;

      > div {
        width: 195px;
      }
    }

    .section.second {
      border: 0;
    }
  }
}
