@import '../../assets/scss/variables.scss';

.upArrow {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-top: 6px;
}

.downArrow {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}


.upArrowIphone {
  margin-top: 6px;
  transform: rotate(180deg);

}

.downArrowIphone {
  transform: rotate(0deg);
}
