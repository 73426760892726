@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.containerPartnershipBanner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: linear-gradient(98.1deg, #23355a 8.68%, #000000 112.98%);
  color: $white-color;
  padding: 0 190px;

  @include screen(max-md) {
    flex-direction: column;
    padding: 0 20px 36px 20px;

    img {
      width: 297px;
      object-fit: contain;
    }
  }

  .containerText {
    width: 480px;

    @include screen(max-md) {
      width: 100%;
    }

    > h1 {
      font-style: normal;
      font-weight: 700;
      font-size: $font-size-36;
      line-height: 48px;
      margin-bottom: 18px;

      @include screen(max-md) {
        margin-top: 20px;
        font-size: $font-size-26;
        line-height: 32px;
      }
    }

    > p {
      font-style: normal;
      font-weight: 400;
      font-size: $font-size-24;
      line-height: 32px;
    }

    .largeButton {
      gap: 5px;
      border-radius: 8px;
      margin-top: 32px;
      width: 300px;
      border-radius: 8px;
      height: 45px;
      background-color: $tertiary-color;
      @include screen(max-md) {
        width: 100%;
      }
    }
  }
}
