@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.leadModal {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 20%;

  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 13px;
    padding-left: 20px;
    padding-right: 20px;

    .question {
      text-align: center;
      margin-bottom: 10px;
    }

    .inputEmail {
      input {
        height: 40px;
      }
    }

    .optins {
      margin-top: 10px;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    width: 100%;
    border-top: 1px solid $line-color;
    margin-top: 10%;

    .button {
      width: 100%;
      height: 50px;
      margin-top: 16px;
    }
  }

  @include screen(xs) {
    height: 85%;
    justify-content: space-between;

    .footer {
      margin-bottom: 20px;
    }
  }

  @include screen(xxs) {
    height: auto;
  }
}

.modalLead {
  width: 320px;

  #closeModal {
    margin-top: 5em !important;
  }

  @include screen(xs) {
    width: 90%;
    height: 100%;
    padding-bottom: 5%;
  }
}
