@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.evaluationSectionContainer {
  max-width: 1400px;
  margin: 0 auto;
  @include flexBox(column);
  padding: 0 50px;
  margin-bottom: 72px;

  @include screen(max-md) {
    margin-bottom: 64px;
    padding: 50px 8px;
  }

  .mainTitle {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 49px;
    margin-bottom: 56px;
    letter-spacing: 0.666667px;
  }

  .titleContainer {
    padding-top: 2px;
    margin-bottom: 25px;
  }
}
