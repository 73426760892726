@import '../../assets/scss/variables.scss';

.categoryTitle {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  > h3 {
    margin-left: 8px;
  }
}
