@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.partnerCard {
  width: 320px;
  height: $list-card-size;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;

  @include screen(xxs) {
    width: 90vw !important;
  }

  @include screen(xs) {
    width: 85vw;
  }

  [class*='image'] {
    border-radius: 12px;
    image-rendering: -webkit-optimize-contrast;
    object-fit: cover;
    cursor: pointer;
  }
}
