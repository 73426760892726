@import '../../assets/scss/variables.scss';

.title {
  font-weight: 600;
  margin: 3px 0;
}

.alignCenter {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.light {
  font-weight: 300;
}

.click {
  cursor: pointer;
}

.primaryColor {
  color: var(--primary-color);
}

.secondaryColor {
  color: var(--text-color);
}

.darkBlueColor {
  color: var(--text-color);
}

.whiteColor {
  color: $white-color;
}

.font-xxsmall {
  font-size: $font-size-10;
}

.font-xsmall {
  font-size: $font-size-12;
}

.font-small {
  font-size: $font-size-14;
}

.font-regular {
  font-size: $font-size-16;
}

.font-medium {
  font-size: $font-size-18;
}

.font-large {
  font-size: $font-size-20;
}

.font-xlarge {
  font-size: $font-size-24;
}

.font-xxlarge {
  font-size: $font-size-30;
}

.font-xxxlarge {
  font-size: $font-size-40;
}

.font-xxxxlarge {
  font-size: $font-size-60;
}

.font-36 {
  font-size: $font-size-36;
}
