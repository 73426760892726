@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

$bb-box-width: 400px;
.container {
  margin: 0 auto;
  max-width: 1400px;
  @include flexBox(row, center, center);

  @include screen(lg) {
    justify-content: flex-start;
    padding: 0 16px;
  }

  @include screen(max-sm) {
    @include flexBox(column, flex-start);
    margin: 50px 0;
    padding: 0;
  }

  .bbBox {
    box-sizing: border-box;
    background-color: $yellow-color;
    height: 420px;
    min-width: 460px;

    border-radius: 12px;
    position: relative;
    padding: 48px 30px;
    @include screen(max-md) {
      min-width: 390px;
    }

    @include screen(max-sm) {
      margin: 0 auto;
      margin-bottom: 16px;
      width: 100%;
      height: 100%;
      max-width: 100%;

      @include flexBox(column, center, flex-start);
      padding: 50px 32px;
    }

    figure {
      margin-bottom: 42px;
    }

    > h2 {
      letter-spacing: -0.9px;
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 3rem;
      max-width: 12ch;
      margin-bottom: 24px;
      color: #163562;

      @include screen(max-sm) {
        max-width: 12ch;
      }
    }

    > p {
      font-size: 1.5rem;
      line-height: 2rem;
      color: #2b458c;
      max-width: 35ch;

      @include screen(max-sm) {
        max-width: 30ch;
      }
    }

    .square {
      box-sizing: border-box;
      display: inline-block;
      position: absolute;
      transform: rotate(45deg);
      background-color: $yellow-color;
      width: 45px;
      height: 45px;
      top: 45%;
      margin: auto;
      right: -5px;
      border-radius: 4px;

      @include screen(max-sm) {
        bottom: -20px;
        right: 0;
        top: 90%;
        position: absolute;
        left: 0;
      }
    }
  }
}
