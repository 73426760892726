@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.saleValueText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  width: 100%;

  .text {
    font-size: 14px;
    word-break: normal;
    line-height: 1.2em;
    color: $secondary-color;
  }

  .saldao {
    font-weight: bold;
  }

  .propertyCard {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    .text {
      align-self: flex-start;
    }
  }

  @include screen(max-lg) {
    .text.campaign {
      font-size: $font-size-14;
    }

    .propertyCard {
      font-size: $font-size-14;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
