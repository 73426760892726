@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

div[class*='infinite-scroll-component'] {
  overflow: hidden !important;
}

.progress {
  display:flex;
  width: 100%;
  justify-content: center;

  .progressBar {
    display: flex;
    width: 100%;
    max-width: 300px;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 10px;
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding: 10px 0px 20px 0px;
  gap: 24px;

  > svg {
    max-width: 100%;
    height: 412px;
  }

  a {
    width: 320px;
    margin-right: 0;
    margin-bottom: 0;

    @include screen(xxs) {
      width: 90vw !important;
    }

    @include screen(xs) {
      width: 85vw;
    }

    > div:last-child {
      padding: 14px !important;
    }

    div[class*='AprraisedValue_row'] {
      p {
        color: $gray10 !important;
        margin-bottom: 8px;
      }
    }

    [class*='novidade'],
    [class*='bankLogo'] {
      z-index: 0;
    }

    [class*='image'] {
      &::after {
        box-shadow: inset 0 4px 35px 0px #0000147d;
      }
    }
  }
}
