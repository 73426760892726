@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.body {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $background-color;
  border-radius: 10px;
  padding: 20px 15px;

  &.modalBody {
    align-items: center;
  }

  h3 {
    padding-right: 30px;
  }

  summary {
    margin-top: -20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
  }

  .modalSummary {
    justify-content: center;
    margin-top: -10px;
  }

  .details {
    margin-top: 10px;
    transition: height 2s linear;
    overflow: hidden;
    text-align: left;

    .documents {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;

      .document {
        flex-direction: column;
        margin: 20px;
        max-width: 180px;

        .docLabel {
          margin-left: 15px;
          margin-top: -6px;

          @include screen(max-sm) {
            margin-left: 0px;
          }
        }

        @include screen(max-sm) {
          text-align: center;
        }
      }

      img {
        width: 163px;
        height: 89px;
        border-radius: 5px;
        margin-top: 4px;
        cursor: pointer;

        @include screen(xs) {
          max-width: 143px;
        }
      }
    }
    summary::-webkit-details-marker {
      display: none;
    }
    > summary {
      text-align: center;
    }
  }

  .documentMiniatureModal {
    max-width: 90%;
    height: 90%;
    overflow-y: hidden;

    @include screen(xs) {
      .clear {
        padding-top: 5.5em;
      }
    }
  }
}
