@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

$medium-screen: 1130px;
$small-screen: 900px;
$xsmall-screen: 500px;

html {
  scroll-behavior: smooth;
}

.bodyText {
  margin-left: 32px;
}

.titleWithIcon {
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin-right: 3px;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  padding: 30px 0 90px 0;
  border-radius: 4px;
  margin: 0 auto;
  border-top: 1px solid #ebebeb;

  .seoText {
    display: flex;
    flex-direction: column;
    text-align: left;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 10px;

    > p {
      margin-bottom: 20px;
    }

    strong {
      color: #23355a;
    }

    @include screen(max-md) {
      margin-top: 15px;
    }
  }

  > .secureSiteContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 30px 0 30px 0px;
    width: 100%;
    height: 80px;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    margin: 30px 0px 30px 0px;

    p {
      color: var(--text-color, #23355a) !important;
    }

    @include screen(max-sm) {
      flex-direction: column;
      height: 180px;
      align-items: flex-start;
      border-top: none;
      margin-top: -30px;
    }

    @include screen(max-md) {
      border-top: none;
      margin-top: -30px;
    }

    .safeSite {
      flex: 0.5;
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        margin: 10px;

        path {
          fill: var(--primary-color, #7896ff) !important;
        }
      }
    }

    .secureBuy {
      flex: 0.7;
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        margin: 10px;

        path {
          fill: var(--primary-color, #7896ff) !important;
        }
      }
    }

    .pagimovelContainer {
      float: right;
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      @include screen(max-sm) {
        margin-left: 56px;
      }
    }

    button {
      background-color: transparent;
      border: 0;
      padding: 0;
      margin: 0;
      cursor: pointer;

      :hover {
        filter: opacity(0.8);
        transition: all 400ms ease;
      }
      @include screen(max-sm) {
        margin-left: 8px;
      }
    }

    .pagimovel {
      display: flex;
      flex-direction: row;
      align-self: center;
      align-items: center;

      .pagimovelLogo {
        height: 28px;
        width: 120px;
        margin-left: 7px;
        margin-right: 40px;
      }
    }
  }

  > .top {
    @include flexBox(row, space-between);
    width: 100%;

    > .container {
      @include flexBox(column, flex-start, flex-start);
      padding-bottom: 10px;
      align-self: flex-start;
      max-width: 29ch;
      line-height: 24px;
      > .companyInformation {
        margin-left: 20px;
        p {
          color: var(--text-color, #23355a) !important;
        }
      }
      > .linkProfiler {
        display: flex;
        text-decoration: none;
        margin: 5px 3px;
        cursor: pointer;
      }
    }
  }

  > .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 15px;

    .rightContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .pagimovel {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    button {
      background-color: transparent;
      border: 0;
      padding: 0;
      margin: 0;
      cursor: pointer;
      :hover {
        filter: opacity(0.8);
        transition: all 400ms ease;
      }
      @include screen(max-sm) {
        margin-left: 8px;
      }
    }

    .pagimovelLogo {
      height: 28px;
      width: 120px;
      margin-left: 7px;
      margin-right: 40px;
    }

    .socialNetworks {
      display: inline-flex;
      height: 32px;
      justify-content: center;

      > .linkNetwork {
        width: 32px;
        margin-right: 8px;

        use {
          fill: var(--primary-color, #7896ff);
        }
      }
    }
    .cnpj {
      display: inline-block;
      margin-top: 8px;
    }
  }

  @include screen(max-md) {
    .top {
      border-bottom: 1px solid #ebebeb;
      padding-bottom: 36px;
      margin-bottom: 36px;
      .container {
        .companyInformation {
          margin-left: 0;
        }
      }
    }
  }

  @include screen(max-lg) {
    padding-right: 15px;
    padding-left: 15px;
  }

  @include screen(min-lg) {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1180px;

    > .top {
      > .container {
        > .companyInformation {
          margin-left: 0;
        }
      }
      @include screen(max-md) {
        @include flexBox(column);
      }
    }
  }
}

.logoContainer {
  max-width: 141px;
  max-height: 64px;
  padding-bottom: 10px;
  > .logo {
    width: 100%;
    height: 100%;
  }
}

@include screen(max-md) {
  .footer {
    padding: 0 16px 24px;
    margin-top: 20px;
    .top {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 36px;

      .container {
        width: 100%;
        margin-bottom: 10px;
        .logoContainer {
          align-self: flex-start;
        }
      }
    }

    .bottom {
      flex-direction: column;
      padding: 10px;
      margin-bottom: 28%;

      p {
        color: var(--text-color, #23355a) !important;
      }

      .rightContainer {
        display: flex;
        flex-direction: column;
        margin-top: 32px;
      }
      .pagimovelLogo {
        margin-left: 0px;
        margin-right: 0px;
      }
      .pagimovel {
        > img {
          margin-left: 12px;
        }
      }
      .socialNetworks {
        margin-top: 24px;
      }
    }
  }
  .logoContainer {
    align-self: center;
    margin-bottom: 10px;
  }
  .cnpj {
    max-width: 25ch;
  }
}

.majorList {
  @include flexBox(row, space-between, flex-start);
  max-width: 860px;
  flex: 1;

  li {
    > h3 {
      font-size: $font-size-18;
      margin-bottom: 16px;
      line-height: 27px;
      font-weight: 700;
      color: var(--primary-color, #7896ff);
    }
  }
  .linksList {
    li {
      margin-bottom: 12px;

      > button {
        background-color: transparent;
        border: none;
        padding: 0;
        color: var(--text-color, #23355a);
      }

      a {
        text-decoration: none;
        color: var(--text-color, #23355a);
        font-size: $font-size-16;
        line-height: 24px;
      }
      :hover {
        color: $gray3;
        transition: all 200ms ease-in-out;
      }
    }
  }
  @include screen(max-md) {
    @include flexBox(column, flex-start, flex-start);
  }

  .termsButtons {
    cursor: pointer;
    text-decoration: none;
    color: #23355a;
    font-size: 16px;
    line-height: 24px;
  }
}
