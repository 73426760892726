@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  button {
    cursor: pointer;
    padding: 0;
    border: 0;
    color: var(--primary-color, $primary-color);
    text-decoration: underline;
    background: none;
    font-size: $font-size-14;

    &:focus,
    &:hover {
      outline: none;
      text-decoration: none;
    }
  }
}

.wrapper-alertModal {
  padding: 0 30px;
}
