@import "../../assets/scss/variables.scss";

.NoticeInformation {
  display: flex;
  margin-top: 15px;

  p {
    margin-left: 5px;
    font-size: 1em;
  }
}