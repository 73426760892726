@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.linkButton {
  color: var(--primary-color, #7896ff);
  cursor: pointer;
  font-size: $font-size-14;
  text-decoration: underline;
  font-weight: 600;
  border: none;
  background: none;

  & a,
  a:link,
  a:visited,
  a:hover,
  a.active {
    color: var(--primary-color, #7896ff);
  }
}

.linkDiv {
  text-align: center;
  margin-top: 4%;

  @include screen(xxs) {
    text-align: start;
    margin-left: 8px;
  }

  @include screen(max-md) {
    margin-top: 0;
  }
}
