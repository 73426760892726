@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.searchHeader {
  display: flex;
  justify-content: center;
  width: 100%;

  .inputContainer {
    width: 100%;

    svg {
      margin-top: 6px;
      width: 20px;
      height: 20px;
      color: $green-color;
    }
  }

  .searchInput {
    height: 40px;
    border-radius: 25px;

    @include screen(xxl) {
      height: 55px;
    }

    > div {
      > input {
        padding: 0px 24px;
      }
      > span {
        @include screen(xxl) {
          top: 8px;
        }
      }
    }
  }

  @include screen(max-sm) {
    padding-top: 0px;

    .inputContainer {
      width: 100%;
    }
  }
}
