@import '../../assets/scss/variables.scss';

.videoWrapper {
  position: relative;
  height: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
  }
}
