@import 'assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

* {
  font-family: $font-family;
}

html {
  font-size: 100%;
  font-family: $font-family;
  color: $blue1;
}

body {
  min-height: 100%;
}

.background-white {
  background-color: $white-color;
  padding-bottom: 50px;
  overflow-x: hidden;
}

.background-gray {
  background-color: $background-color;
  padding-bottom: 70px;
}

.fixedTop {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
}
