@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.swiperContainer {
  width: 100%;
  max-width: 1388px;
  overflow: hidden;
  list-style-type: none;
}

.timeline {
  list-style-type: none;
  display: flex;
  text-align: center;
  flex-direction: row;
  padding-top: 20px;
}

.timeline li {
  transition: all 200ms ease-in;
}

.timestamp {
  width: 250px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 100px;
  font-weight: 100;
}

.status {
  padding: 16px 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-top: 7px solid $gray2;
  position: relative;
  transition: all 200ms ease-in;
}

.status ul li {
  padding-top: 20px;
  text-align: left;
  list-style-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"><circle fill="%237896ff" cx="7" cy="7" r="2"/></svg>');
}

.status ul li:before {
  content: '';
  width: 24px;
  height: 24px;
  background-color: $white-color;
  border-radius: 25px;
  border: 6px solid $primary-color;
  position: absolute;
  top: -20px;
  left: 44%;
}

.buttonContainer {
  display: flex;
  width: 100%;
  max-width: 1388px;
  justify-content: space-between;
  position: absolute;
  margin-top: 95px;
}

.button {
  z-index: 5;
  display: flex;
  align-items: center;
  text-decoration: none;
  border: 0;
  background: none;
  cursor: pointer;
  margin: 0px;

  &:focus {
    outline: none;
  }

  @include screen(xs) {
    margin: 0px 5px;
  }
}

.button.prevSlideIphone {
  transform: rotate(90deg);
}

.button.nextSlideIphone {
  align-self: flex-end;
  transform: rotate(270deg);
}

.button.nextSlide {
  align-self: flex-end;

  span {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: 'FlipH';
  }
}

.date {
  min-height: 97px;
}

.year {
  font-weight: 900;
}

ul .noLink {
  text-decoration: none;
}

ul .link {
  text-decoration: none;
  p {
    color: $blue1;
  }
  transition: font-size color 50ms ease-in-out;
}

ul .link:hover {
  font-size: $font-size-18;
  p {
    color: $primary-color;
  }
}
