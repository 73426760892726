.indicator {
  all: unset;
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.upArrow {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-top: 6px;
}

.downArrow {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
