@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.mediaSectionContainer {
  background-color: #f9f9f9;
  width: 100%;
  padding-bottom: 30px;

  @include screen(max-sm) {
    margin: 0 auto;
  }

  h2 {
    margin-top: 60px;
    font-size: 36px;
    text-align: center;
    letter-spacing: 0.666667px;
    line-height: 40px;
    font-weight: 700;

    @include screen(max-sm) {
      margin: 42px auto 0;
      max-width: 15ch;
    }
  }

  .titleContainer {
    padding-top: 2px;
    margin-bottom: 25px;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    padding: 20px 20px 42px 20px;
    justify-content: center;
  }

  .checkArticles {
    width: 230px;
    height: 44px;
    border-radius: 8px;
    background-color: $tertiary-color;
    font-size: $font-size-16;
    color: white;
  }
}
