@import '../../assets/scss/variables.scss';

.info {
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;

  > span {
    font-weight: bold;
    font-size: $font-size-16;
  }
}

.iconRotate {
  transform: rotate(180deg);
  transition: all 0.2s;
}

.icon {
  transition: all 0.2s;
}

.text {
  margin-top: 10px;
}
