@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';

section {
  .stage {
    padding: 20px 50px 50px 50px;

    @include screen(max-md) {
      padding: 20px 0 50px 0 !important;
    }
  }
}
