@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.cardContainer {
  width: 100%;
  @include flexBox(row, space-around);
  margin-bottom: 32px;
  flex-wrap: wrap;

  @include screen(max-md) {
    @include flexBox(column, center);
  }

  .rate {
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #f9f9f9;
    @include flexBox(column);
    max-width: 360px;
    min-height: 330px;
    min-width: 345px;
    padding: 24px 16px 0;

    @include screen(max-md) {
      max-width: 70vw;
      padding: 0;
      margin-top: 0;
    }

    > .title {
      font-size: 94px;
      line-height: 98px;
      font-weight: 700;
      > .divider {
        font-size: 48px;
        line-height: 68px;
      }
    }
  }

  .starContainer {
    @include flexBox();
  }
}
