@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.container {
  @include flexBox(column);
  padding: 56px 120px;
  background-color: #f9f9f9;
  @include screen(max-md) {
    padding: 56px 16px;
  }
  .mainTitle {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 49px;
    margin-bottom: 56px;
    letter-spacing: 0.666667px;
  }
  .main {
    @include flexBox(row, space-between, flex-start);
    width: 100%;
    max-width: 1000px;
    .card {
      display: flex;
      align-items: center;
      flex-direction: column;

      min-width: 162px;
      &.first {
        align-self: center;
        > figure {
          margin-bottom: 16px;
        }
      }
      figure {
        max-width: 320px;
        text-align: center;
      }
      h2,
      h3,
      p {
        text-align: center;
      }
      h2 {
        max-width: 25ch;
        font-weight: 700;
      }
      .info {
        font-size: 60px;
        font-weight: 900;
      }
      p {
        max-width: 30ch;
      }
      @include screen(max-md) {
        margin-bottom: 24px;
      }
    }
    @include screen(max-md) {
      @include flexBox(column);
    }
  }
}
