@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.highlightedSection {
  @include flexBox(column);
  text-align: center;
  width: 100%;
  padding: 48px 0;
  background-color: #f9f9f9;

  > h1 {
	  font-weight: 700;
  }

  &.agroContainer {
    background-color: white;
    padding: 0 15px;
    padding-top: 8px;
    @include flexBox(row, space-around, center);
		width: 0 auto;
		overflow-x: auto;

		@include screen(max-md) {
			padding: 0 8px;
		}

		@include screen(max-sm) {
			width: 90%;
			padding: 0 8px;
		}

    .highlightedList {
    	@include flexBox(row, flex-start, flex-start);
      		margin: 0 auto;
			overflow-y: hidden;
			overflow-x: auto;
			width: 100%;
    	@include screen(max-md) {
				overflow-x: auto;
				margin: 0 auto;
			}

    	@include screen(max-sm) {
				overflow-x: auto;
				padding: 0;
				margin: 0 auto;
			}
    }

    @include screen(max-sm) {
      padding: 15px 0;
    }
  }

  .highlightedList {
		margin: 0 auto;
		margin-top: 24px;
		width: 1400px;
		position: relative;
		box-sizing: border-box;
		overflow-x: hidden;
		max-width: 100vw;

		[class="react-multi-carousel-list"]{
			width: 100%;
			padding: 0 16px;
		}

		>button{
			margin: 0 -2.5% !important;
			background-color: white !important;
			border: 1px solid #f0f0f0;
			box-shadow: 0px 15px 20px rgba(197, 197, 197, 0.4);
			color: #333 !important;
			margin: 0 auto;
			transition: all 0.3s ease 0s;
			top: 50%;

			&::before{
				color: #888;
			}
			&:hover{
				background-color: #f4f4f4 !important;
			}
		}
		ul{
			li{
				margin-right: 0;
				&:last-child{
					margin-right: 0;
				}
			}
		}

    @include screen(xl) {
		margin: 24px auto;
    }

		@include screen(md){
			margin: 24px auto;
			padding: 0 8px;
			>ul{
				>li{
					margin-right: 24px;
					&:last-child{
						margin-right: 0;
					}
				}
			}
		}

		@media (max-width: 1200px) and (min-width: 900px){
			ul{
				width: 100%;
				padding: 0 24px;
				li{
					margin-right: 26px;
					&:last-child{
						margin-right: 0;
					}
				}
			}
		}

		@include screen(max-sm){
			padding: 0 8px;
			margin: 24px -8px 16px;
			ul{
				width: 100%;
				li{
					margin-right: 16px;
					&:last-child{
						margin-right: 0;
					}
				}
			}
		}

	&.multiple{
    margin: 24px 48px 32px;
		[class="react-multi-carousel-list"]{
			width: 100%;
		}
		ul{
			padding: 0 16px;
			li{
				margin-right: 26px;
				max-width: 320px;
			}
		}

		@media (max-width: 1200px) and (min-width: 800px){
			ul{
				li{
					margin-right: calc(128px - 10vw);
					&:last-child{
							margin-right: calc(80px - 5vw);
					}
				}
			}
		}

		@include screen(md){
			ul{
				li{
					margin-right: 24px;
					&:last-child{
						margin-right: 0;
					}
				}
			}
		}
		
		@include screen(max-sm){
			padding: 0 8px;
    		margin: 24px 0 16px;
			ul{
				li{
					margin-right: 24px;

					&:last-child{
						margin-right: 0;
					}
				}
			}
		}
	}
}

  .buttonOpportunity {
    text-decoration: none;
		width: 230px;
		height:44px;
		button{
			width: 100%;
			height: 100%;
			border-radius: 8px;
		}
  	}

  .loading {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@include screen(md){
	.highlightedList{
  	padding: 36px 8px 24px;
		>ul{
			>li{
				margin-right: 24px;
				&:last-child{
					margin-right: 0;
				}
			}
		}
	}	
}

@include screen(max-sm){
	.highlightedList{
  	padding: 24px 8px 24px;
		>ul{
			>li{
				margin-right: 24px;
				&:last-child{
					margin-right: 0;
				}
			}
		}
	}
}

.swiperButton {
  all: unset;
  cursor: pointer;
  // all:unset specified for IE
  background: none;
  border: none;
  padding: 0;
  outline: inherit;
}

.buttonContainer {
  display: flex;
  width: 100%;
  max-width: 1388px;
  justify-content: space-between;
  position: absolute;
  margin-top: 32px;

  .arrow {
    width: 22px;
  }
}

.button {
  z-index: 1;
  display: flex;
  align-items: center;
  text-decoration: none;
  border: 0;
  background: none;
  cursor: pointer;
  margin: 0px;

  &:focus {
    outline: none;
  }

  @include screen(xs) {
    margin: 0px 5px;
  }
}