@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.simulatorContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  zoom: 0.9;
  height: 100%;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  // for IE and Edge
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    zoom: 1;
  }

  @include screen(xs) {
    padding-bottom: 5%;

    @supports (-webkit-touch-callout: none) {
      margin-top: 29px;
    }
  }

  .top {
    @include flexBox();

    > .title {
      color: #666666;
      line-height: 26px;
      font-weight: 300;
    }
  }

  > .header {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: $white-color;
    z-index: 3;
    padding: 1em 0;

    @include screen(xs) {
      padding: 2em 0;
    }

    .info {
      display: flex;
      flex-direction: column;

      .description {
        margin-top: 14px;
      }
    }
  }

  > .main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 29px;
    position: relative;
    max-width: 90%;
    margin: 0 auto;

    .contactText {
      margin-top: 16px;
      color: $secondary-color;
      line-height: 24px;
    }

    .modalLabel {
      line-height: 21px;
      font-weight: 300;
      color: #666666;

      > p {
        font-size: 14px;
      }
    }

    input {
      color: $secondary-color;
    }

    > .inputName {
      width: 100%;
      margin-top: 12px;
    }

    > .inputPhone,
    > .inputEmail {
      margin-top: 3px;
    }

    > .selectPeriod,
    > .selectDay {
      margin-top: 6px;
    }

    > .inputPhone,
    > .selectPeriod {
      width: 50%;
      padding-left: 3%;
      box-sizing: border-box;
    }

    > .inputEmail,
    > .selectDay {
      width: 50%;
      padding-right: 2%;
      box-sizing: border-box;
    }

    > .inputSubject {
      width: 100%;
      margin-top: 16px;

      label {
        p,
        select {
          color: #666666;
          font-size: 14px;
        }
      }

      span {
        font-size: 12px;
      }
    }

    input {
      height: 40px;
    }

    > .inputName,
    > .inputPhone {
      position: relative;

      > span {
        position: absolute;
        bottom: -16px;
        right: 0;
        font-size: $font-size-12;
      }
    }

    > .inputEmail {
      position: relative;

      > span {
        position: absolute;
        bottom: -16px;
        right: 11px;
        font-size: $font-size-12;
      }
    }

    .valuesContainer {
      margin-top: 1rem;
      background-color: #f4f4f4;
      border-radius: 8px;
      width: 100%;
      padding: 16px 24px;

      p {
        @include flexBox(row, space-between);
        margin-top: 4px;
        font-size: 16px;
        line-height: 24px;

        &:first-child {
          margin-top: 0;
        }

        &.highlighted {
          font-weight: 700;
        }

        &.interest {
          color: #666666;
          font-weight: 300;
        }

        &.footNote {
          display: block;
          text-align: right;
          align-self: flex-end;
          color: #666666;
          font-weight: 300;
          font-size: 12px;
        }
      }
    }

    @include screen(max-sm) {
      .selectPeriod {
        width: 100%;
        padding-left: 0;
      }

      .selectDay {
        width: 100%;
      }
    }

    .warning {
      position: absolute;
      bottom: -5%;
      left: 0;
      color: $tertiary-color;
      font-size: $font-size-14;

      @include screen(xs) {
        position: static;
        max-width: 40ch;
      }
    }

    > .warnMessage {
      margin: 0.5rem 0.25rem;
      font-size: 14px;
      text-align: justify;
      color: $secondary-color;

      strong {
        color: $secondary-color;
      }
    }
  }

  .footer {
    @include flexBox(column);
    margin-top: 2%;
    bottom: 0;
    position: sticky;
    z-index: 3;
    background-color: $white-color;
    width: 100%;
    padding: 0.4em 0 2em;

    @include screen('max-sm') {
      padding: 0.4em 0 2.5em;
    }

    .buttonContainer {
      @include flexBox(row);
      width: 100%;
      margin: 0 1em;

      > .button {
        width: 100%;
        max-width: 280px;
        margin: 5px;
        border-radius: 8px;
        height: 52px;

        @include screen('xs') {
          max-width: 180px;
        }
      }
    }
  }
}

.simulatorContainerbb {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  .tooltip {
    position: relative;
    display: inline-block;
    cursor: help;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 100%;
    max-width: 400px;
    background-color: #888888;
    color: #fff !important;
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    font-size: 12px !important;
    left: 2rem;
    top: -4rem;
    @media (max-width: 475px) {
      max-width: 250px;
    }
  }
    .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .headerbb {
    width: 100%;
    z-index: 3;
    min-height: 88px;
    background-image: url('../../../assets/images/background-modalBB.png');
    background-repeat: no-repeat;
    background-position: center center;
  }

  .topbb {
    @include flexBox();
    height: 100%;

    > .titlebb {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 37px;
      color: #2a458c;
    }
  }

  .inputNamebb {
    width: 100%;

    input {
      color: #262626;
      font-size: 1rem;
      width: 100%;
      border: 1px solid #888888;
      border-radius: 4px;
      height: 40px;
    }
  }

  .inputbb {
    width: 50%;

    input {
      color: #262626;
      font-size: 1rem;
      width: 100%;
      border: 1px solid #888888;
      border-radius: 4px;
      height: 40px;
    }
  }

  .marginLeft {
    padding-left: 1.5%;
    box-sizing: border-box;
  }

  .marginRight {
    padding-right: 1.5%;
    box-sizing: border-box;
  }
  .modalLabelbb {
    p {
      font-size: 14px;
      line-height: 21px;
      font-weight: 300;
      color: #666666;
    }
  }
  .optinsbb {
    width: 100%;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 10;
    .buttonbb {
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      background: none;
      outline: none;
      border: none;
      gap: 10px;
      .checkboxbb {
        transform: scale(1.2);
        cursor: pointer;
        margin: 5px 0 0 0;
      }
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #101010;
        text-align: left;
      }
    }
  }

  .main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;
    max-width: 472px;
    margin: 24px auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      zoom: 1;
    }

    @include screen(xs) {
      padding: 0 4% 5% 4%;

      @supports (-webkit-touch-callout: none) {
        margin-top: 29px;
      }
    }

    .important {
      margin-top: 23px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #101010;
    }

    .contactText {
      margin-top: 16px;
      color: $secondary-color;
      line-height: 24px;
    }
  }
  .footerbb {
    margin-top: auto;
    position: sticky;
    z-index: 3;
    background-color: $white-color;
    width: 100%;

    .buttonContainer {
      @include flexBox(row);
      width: 100%;
      margin-bottom: 10px;
      gap: 17px;

      > .button {
        width: 100%;
        max-width: 240px;
        border-radius: 8px;
        height: 52px;

        @include screen('xs') {
          max-width: 165px;
        }
      }
    }
  }
}
