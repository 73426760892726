@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.carousel {
  width: 100%;
  overflow: hidden;
  margin-bottom: 92px;
  position: relative;

  @include screen(max-sm) {
    margin-bottom: 56px;
  }

  > [class*='swiper-container'] {
    .swiper {
      transform: translate3d(0px, 0px, 0px);
      white-space: nowrap;
      width: 100%;
      height: 100%;

      @include screen(max-sm) {
        max-height: 750px;
      }
      .slide {
        width: 100% !important;
      }
    }
  }

  .button {
    cursor: pointer;
    width: 100% !important;
  }
  .navigator {
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }

  .countDown {
    position: absolute;
    bottom: 22%;
    right: 20%;

    @include screen(xs) {
      right: 24%;
      bottom: 30%;
    }

    @include screen(sm) {
      right: 35%;
      bottom: 30%;
    }

    @media (min-width: 768px) and (max-width: 950px) {
      bottom: 37%;
      right: 69%;
    }

    @media (min-width: 950px) and (max-width: 1024px) {
      bottom: 41%;
      right: 71%;
    }

    @include screen(lg) {
      bottom: 42%;
      right: 70%;
    }

    @include screen(xl) {
      bottom: 40%;
      right: 70%;
    }

    @include screen(xxl) {
      bottom: 40%;
      right: 70%;
    }
  }
}

.container {
  width: 100%;
  height: 100%;
}
