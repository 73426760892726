@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.container {
  @include flexBox(row, flex-start);
  padding: 24px 18px;
  margin: 0 auto;
  max-width: 1400px;
  justify-content: center;

  @include screen(max-lg) {
    overflow-x: scroll !important;
    justify-content: flex-start;
  }

  > button {
    background-color: white !important;
    margin: 0 -4% !important;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 15px 20px rgba(197, 197, 197, 0.4);
    color: #333 !important;
    margin: 0 auto;
    transition: all 0.3s ease 0s;

    &::before {
      color: #888;
    }

    &:hover {
      background-color: #f4f4f4 !important;
    }
  }

  > ul {
    width: 100%;
    > li {
      margin-right: 24px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
