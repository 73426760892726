@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.finishedPropertyModal {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  
  .header {
    text-align: center;
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .boldInformation{
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .inputProps {
      width: 100%;

      input {
        height: 40px;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .submit {
      width: 30%;
      margin-top: 20px;
    }
  }

  .info {
    width: 80%;
    text-align: center;
  }

  .link{
    text-decoration: none;
  }

  @include screen(max-sm) {
    padding: 40px 0px;

    .info {
      width: 100%;
    }
    
    .footer {
      .submit {
        min-width: 40%;
      }
    }
  }
}
