@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  z-index: 2;
  height: 28px;
  align-self: flex-start;
}

.text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  margin-left: 10px;
  margin-bottom: 6px;
}
.icon {
  width: 25px;
}

.label {
  margin-left: 3px;
}
