@import '../../assets/scss/variables.scss';

.one-column,
.two-columns {
  display: flex;
  margin-top: 1px;

  > label {
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 10px;
  }
}

.one-column {
  flex-direction: column;
}

.two-columns {
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  > label {
    min-width: 46%;
  }
}
