@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.icon {
  height: 75%;
  width: 100%;
  cursor: pointer;
}

.darkColor {
  color: $secondary-color;
}

.docWrapper {
  display: flex;
  flex-direction: column;
  margin: 5px 16px;
  flex-wrap: wrap;

  @include screen(xs) {
    margin-left: 5px;
    margin-right: 6px;
    max-width: 143px;

    > .document {
      cursor: pointer;
      > .icon {
        display: inline;
      }
    }
  }

  > .document {
    width: 180px;
    height: 89px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: 163px;
    overflow: hidden;
    border: solid 1px $gray2;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-bottom: 4px;
    cursor: pointer;

    @include screen(xs) {
      max-width: 143px;
    }

    .documentMiniature {
      position: absolute;
      top: -1px;
      left: -1px;
    }

    .onClickPanel {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;
    }
  }
}

.documentMiniatureModal {
  max-width: 90%;
  height: 90%;
  overflow-y: hidden;

  @include screen(xs) {
    .clear {
      padding-top: 5.5em;
    }
  }

  &.imageFileModal {
    overflow-y: scroll;
  }
}

.iframe {
  margin-top: 5%;
  height: 90%;
  width: 100%;
}

@include screen(xs) {
  .documentMiniatureModal {
    max-height: 100%;
    padding-bottom: 30%;

    .androidWrapper {
      padding-top: -200px;
      padding-bottom: 40%;
    }

    .wrappedModal {
      padding-top: 0%;
    }
  }

  .iframe {
    margin-top: 15%;
    height: 100%;
  }
}

.imageFile {
  width: 100%;
}

.loading {
  display: inline-flex;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 50%;
  right: 46%;
  z-index: -1;
}

.link {
  text-decoration: none;
  color: $blue1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.disclaimerModal {
  z-index: 13;
  @include flexBox(column);
  height: auto;

  @include screen(xs) {
    padding: 15px;
  }

  > p {
    margin-bottom: 5%;
  }
}

.disclaimerBackdrop {
  z-index: 12;
}
