@import '../../assets/scss/variables.scss';

.primaryColor {
  fill: $primary-color;
}

.cdnPrimaryColor {
  fill: var(--primary-color,#7896ff);
}

.darkColor {
  fill: $secondary-color;
}

.grayColor {
  fill: $gray4;
}

.whiteColor {
  fill: $white-color;
}

.ctaPrimaryText {
  fill: var(--cta-primary-text, white);
}
.ctaSecondaryText {
  fill: var(--cta-secondary-text, white);
}

.orangeColor {
  fill: $orange-color;
}

.greenColor {
  fill: $green-color !important;
}
.redColor {
  fill: $tertiary-color;
}

.rotate {
  transform: rotate(135deg);
}

svg {
  cursor: unset !important;
}
.starPrimaryColor {
  stop-color: $primary-color;
  stop-opacity: 1;
}
.starGrayColor {
  stop-color: $gray2;
  stop-opacity: 1;
}

.grad1 {
  fill: url(#grad1);
}
.grad2 {
  fill: url(#grad2);
}
.grad3 {
  fill: url(#grad3);
}
.grad4 {
  fill: url(#grad4);
}
.grad5 {
  fill: url(#grad5);
}
