@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.input {
  input {
    border: 1px solid #dadade !important;
    border-radius: 8px !important;
    height: 40px !important;

    &::placeholder {
      color: $lavender1;
      font-weight: 500;
    }
  }

  svg {
    fill: var(--primary-color, #7896ff);
  }
}

.options {
  position: absolute;
  transform: translateY(-15px);
  visibility: hidden;
  display: none;
  transition: opacity 0s, transform 0.1s ease-in-out;
  font-size: $font-size-12;
  overflow: hidden;
  width: 99%;
  border: 1px solid $line-color;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  ::-webkit-scrollbar {
    margin-right: 5px;
  }

  @include screen(max-sm) {
    font-size: 16px;
  }

  > .option {
    display: flex;
    align-items: center;
    background-color: $white-color;
    padding-left: 10px;
    height: 32px;
    color: var(--text-color);

    &:hover {
      background-color: #f8f8f8;
      cursor: pointer;
    }

    &:focus {
      background-color: #f8f8f8;
      outline: 0;
    }
  }
}

.open {
  max-height: 260px;
  visibility: visible;
  display: block;
  transform: translateY(0);
  z-index: 2;
}

.tooManyItems {
  overflow-y: scroll;
}

.alerter {
  position: relative;
}

.placeholderTop {
  font-size: $font-size-14;
  color: var(--text-color);
  opacity: 0.8;
  top: 16px;
  left: 11.5px;
  position: absolute;
  pointer-events: none;
}
