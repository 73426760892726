@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.container {
  > .rangeSlider {
    padding: 0 8px 0 6px;
    margin-top: 8px;
  }
}

.rangeInput {
  flex: 1;
  margin-left: 5px;
}

.inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 13px;

  // > div:nth-child(1),
  // > div:nth-child(3) {
  //   width: 120px;
  // }

  > p {
    color: $lavender2;
    line-height: 32px;
  }
}
