@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.creditMatModal {
  display: flex;
  flex-direction: column;
  // zoom: 1.025;
  justify-content: center;
  align-items: center;
}

.formContainer {
  height: 600px;
  .clearForm {
    background-color: transparent;
    width: 25px;
  }
  .form {
    @include flexBox();
    width: 100%;
    height: 100%;
  }
}

.modalCreditMat {
  max-width: 900px;
  min-height: 600px;
  padding: 0px;
  background-size: contain;
  border-radius: 10px;
  border: none;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.closeButtonCreditMat {
  background-color: $blue0 !important;
  height: 37px !important;
  width: 37px !important;

  use {
    fill: $gray8;
  }
}
