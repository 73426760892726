@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

$header-height: 71px;
$header-mobile-height: 50px;

.header {
  top: 0;
  z-index: 4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--header-bg, #7896ff);
  height: $header-height;
  padding-left: 17px;
  padding-right: 50px;

  &.blackout {
    background-color: $blue1;
  }

  &.fixed {
    position: sticky;
    position: -webkit-sticky;
  }

  @include screen(max-sm) {
    height: $header-mobile-height;
  }

  .outletText {
    font-weight: 400;
    margin-left: 0.25rem;
    color: var(--header-text, $white-color);
    @include screen(max-md) {
      display: none;
    }
  }
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logo {
  width: auto;
  height: 55px;

  display: flex;

  > .logoImg {
    width: auto;
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
}

.listItem {
  display: inline;
  padding-left: 20px;
}

.link {
  color: var(--header-text, $white-color);
  font-size: $font-size-16;
  text-decoration: none;
}

.linkActive {
  color: var(--header-text-active, $white-color);
  text-decoration: underline;
  font-weight: 600;
}

.burger {
  display: block;
  width: 25px;
  border: none;
  background: transparent;
  position: relative;
  z-index: 4;
  outline: none;
  padding: 8px 0;
  > div,
  &::after,
  &::before {
    content: '';
    align-self: stretch;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background: var(--header-text, $white-color);
    transition: 0.3s ease;
    transform-origin: center;
  }
  &::after,
  &::before {
    position: absolute;
    left: 0;
  }
  &::after {
    bottom: 0;
  }
  &::before {
    top: 0;
  }
}

.burgerOpen {
  > div {
    opacity: 0;
  }
  &::after,
  &::before {
    background: var(--primary-color, $primary-color);
  }
  &::before {
    transform: rotate(-45deg) translateY(-30%);
    top: 50%;
  }
  &::after {
    transform: rotate(45deg) translateY(-30%);
    top: 50%;
    bottom: auto;
  }
}

.mobileMenu {
  display: flex;
  flex-direction: row;

  .labelMenu {
    color: var(--header-text, $white-color);
    margin-right: 10px;
    font-size: $font-size-14;
  }
}

@include screen(min-md) {
  .burger,
  .mobileMenu {
    display: none;
  }
}

@include screen(max-sm) {
  .navbar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $gray8;
    z-index: 3;
    display: none;
  }

  .list {
    width: 90%;
    padding: 0;
    margin: 2.5em;
    text-align: left;
  }

  .listItem {
    margin: 0 0 0.9em;
    font-size: $font-size-24;
    display: block;

    a {
      color: var(--text-color);
    }
  }

  .link {
    color: $blue1;
    font-size: inherit;
  }

  .navbarOpen {
    display: flex;
    align-items: flex-start;
  }

  .logoContainer {
    flex: 1;

    > .logo {
      width: auto;

      > .logoImg {
        margin-top: 7px;
        width: auto;
        height: 40px;
      }
    }
  }

  .header {
    justify-content: flex-start;
    padding: 0 16px;
  }

  .burger,
  .mobileMenu {
    display: flex;
  }
}
