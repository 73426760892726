@import '/../../assets/scss/variables.scss';
@import '/../../assets/scss/mixins.scss';

.sectionContainer {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 32px;

  > header {
    padding: 0 15px;

    > h2 {
      font-weight: 700;
    }
  }

  .statesCTA {
    margin: auto;
    display: block;
    margin-top: 36px;
    width: 230px;
    height: 44px;
    background-color: $tertiary-color;
    font-size: $font-size-16;
    color: white;
  }
}
