@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.valuesContainer {
  @include flexBox(column);

  [class*='AprraisedValue'] {
    margin-bottom: 8px;
    p {
      color: var(--text-color);
      font-size: $font-size-16;
    }
  }

  [class*='discount'] {
    margin-bottom: 12px;
    p[class*='text'] {
      font-size: $font-size-16;
      color: var(--text-color);
    }
  }
  [class*='saleValueText'] {
    margin-bottom: 8px;
    p {
      color: var(--text-color);
      font-size: $font-size-16;
    }
  }

  &.activeCampaign {
    [class*='discount'] {
      order: 1;
    }

    > .promotionPrice {
      order: 2;
    }
  }

  span[class*='discount'] {
    p {
      font-size: 16px;
    }
    p[class*='percent'] {
      font-size: 24px;
    }
  }

  > span[class*='saleValueText'] {
    > p {
      font-size: 16px;
    }
    > strong {
      font-size: 24px;
      &[class*='campaign'] {
        font-size: 16px;
      }
    }
  }
  div[class*='row'] {
    > p {
      font-size: 16px;
    }
  }
}
